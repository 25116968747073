import React, {useState} from 'react';
import postWithAuth from "../../context/postWithAuth";

function EditKnowShowChartModal({ editKnowShowChartModal, setEditKnowShowChartModal, selectedStandardSet, knowShowElements, user, standardCode, setKnowShowCharts, setAssessmentItems }) {
    const [editorState, setEditorState] = useState([]);
    const [newKnow, setNewKnow] = useState(null);
    const [newRecall, setNewRecall] = useState(null);
    const [newShow, setNewShow] = useState(null);
    const [newVocab, setNewVocab] = useState({});
    const [tempKnowShow, setTempKnowShow] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editOldElements, setEditOldElements] = useState([]);
    const [retireElements, setRetireElements] = useState([]);

    if ( !editKnowShowChartModal ) return null;
    const publishItem = () => {
        if (tempKnowShow.length > 0 || editOldElements.length > 0 || retireElements.length > 0) {
            let knowShowChartPayload = {
                standardCode: standardCode,
                modalData: editKnowShowChartModal,
                selectedStandardSet: selectedStandardSet,
                newKnowShowElements: tempKnowShow,
                editedKnowShowElements: editOldElements,
                standardTitle: editKnowShowChartModal.title,
                retiredElements: retireElements,
                user: user
            };
            setIsLoading(true);
            postWithAuth('/planning/postEditKnowShowChart', user.userAttributes["custom:school"], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: knowShowChartPayload
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        console.error('Failed to publish Know Show chart');
                        setIsLoading(false)
                    }
                })
                .then(async data => {
                    await setKnowShowCharts(data.knowShowCharts);
                    await setAssessmentItems(data.assessmentItems);
                    setEditKnowShowChartModal(null)
                })
                .catch(error => {
                    console.error('Error publishing Know Show chart:', error);
                    setIsLoading(false)
                });
        } else {
            setEditKnowShowChartModal(null);
        }

    };

    const onClose = () => {
        if (window.confirm("Are you sure? Closing this window will remove all Know Show content.")) {
            setEditKnowShowChartModal(null)
        } else {
            return null
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleAddKnow = () => {
        if (!editorState.includes("know")) {
            let newEditorState = [...editorState];
            newEditorState.push("know");
            setEditorState(newEditorState);
        }
    }

    const handleAddRecall = () => {
        if (!editorState.includes("recall")) {
            let newEditorState = [...editorState];
            newEditorState.push("recall");
            setEditorState(newEditorState);
        }
    }

    const handleAddShow = () => {
        if (!editorState.includes("show")) {
            let newEditorState = [...editorState];
            newEditorState.push("show");
            setEditorState(newEditorState);
        }
    }

    const handleAddVocab = () => {
        if (!editorState.includes("vocab")) {
            let newEditorState = [...editorState];
            newEditorState.push("vocab");
            setEditorState(newEditorState);
        }
    }
    const handleNewKnowChange = (e) => {
        setNewKnow(e.target.value);
    }

    const handleNewRecallChange = (e) => {
        setNewRecall(e.target.value);
    }

    const handleNewShowChange = (e) => {
        setNewShow(e.target.value);
    }
    const handleNewVocabChange = (e) => {
        let newTempVocab = {...newVocab};
        newTempVocab[e.target.id] = e.target.value;
        setNewVocab(newTempVocab);
    }

    const handleIntegrateKnow = () => {
        let newKnowShows = [...tempKnowShow];
        (newKnow) && newKnowShows.push({type: "Know", text: newKnow, mostRecentEval: "Not Yet Assessed", assessmentItems: 0});
        setTempKnowShow(newKnowShows);

        let newEditorState = [...editorState];
        newEditorState.pop("know");
        setEditorState(newEditorState);
        setNewKnow(null);
    }

    const handleIntegrateRecall = () => {
        let newKnowShows = [...tempKnowShow];
        if (!newKnowShows.filter(item => item.type === 'Recall' && item.active).map(item => item.title).includes(newRecall) && !knowShowElements.filter(item => item.type === 'Recall').map(item => item.title).includes(newRecall)) {
            (newRecall) && newKnowShows.push({type: "Recall", title: newRecall, mostRecentEval: "Not Yet Assessed", assessmentItems: 0});
            setTempKnowShow(newKnowShows);

            let newEditorState = [...editorState];
            newEditorState.pop("recall");
            setEditorState(newEditorState);
            setNewRecall(null);
        } else {
            window.alert('This standard already has a recall set with this title, please consider a different title for this set.')
        }

    }

    const handleIntegrateShow = () => {
        let newKnowShows = [...tempKnowShow];
        (newShow) && newKnowShows.push({type: "Show", text: newShow, mostRecentEval: "Not Yet Assessed", assessmentItems: 0});
        setTempKnowShow(newKnowShows);

        let newEditorState = [...editorState];
        newEditorState.pop("show");
        setEditorState(newEditorState);
        setNewShow(null);
    }

    const handleIntegrateVocab = () => {
        let newKnowShows = [...tempKnowShow];
        if (Object.keys(newVocab).includes("termEdit") && Object.keys(newVocab).includes("defEdit")) { newKnowShows.push({type: "Vocab", term: newVocab.termEdit, definition: newVocab.defEdit, mostRecentEval: "Not Yet Assessed", assessmentItems: 0}) } else { return null}
        setTempKnowShow(newKnowShows);

        let newEditorState = [...editorState];
        newEditorState.pop("vocab");
        setEditorState(newEditorState);
        setNewVocab({})
    }

    const clearTempKnowShow = (e) => {
        let newKnowShows = [...tempKnowShow];
        setTempKnowShow(newKnowShows.filter(item => !(item.text === e.target.id)));
    }

    const clearTempRecall = (e) => {
        let newKnowShows = [...tempKnowShow];
        setTempKnowShow(newKnowShows.filter(item => !(item.title === e.target.id)));
    }

    const clearTempVocab = (e) => {
        let newKnowShows = [...tempKnowShow];
        setTempKnowShow(newKnowShows.filter(item => !(item.term === e.target.id)));
    }

    const handleEditorDoubleClick = (id, value) => {
        if (!editOldElements.flatMap(item => item.id).includes(id)) {
            setEditOldElements([...editOldElements, {id: id, oldText: value, newText: value, type: 'other'}]);
        } else {
            return
        }
    }

    const handleEditorDoubleClickRecall = (id, value) => {
        if (!editOldElements.flatMap(item => item.id).includes(id)) {
            setEditOldElements([...editOldElements, {id: id, oldText: value, newText: value, type: 'Recall'}]);
        } else {
            return
        }
    }

    const handleEditOldElementChange = (e) => {
        let tempEdits = editOldElements;
        let changer = tempEdits.find(item => item.id === e.target.id)
        changer.newText = e.target.value;
        setEditOldElements([... editOldElements.filter(item => item.id !== e.target.id), changer])
    }

    const markForRetire = (element) => {
        if (retireElements.includes(element._id.$oid)) {
            setRetireElements(retireElements.filter(item => item !== element._id.$oid))
        } else {
            setRetireElements([...retireElements, element._id.$oid])
        }
    }


    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
            <div className={"relative bg-dutch_white-500 rounded p-2 mx-auto min-h-[30%] max-h-[90%] w-[95%]"}>
                <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={"p-2 text-columbia_blue-800 cursor-black flex h-full justify-between"}>
                    <div className={"bg-columbia_blue-300 h-auto rounded p-6 flex flex-col text-left w-[30%] overflow-y-auto text-columbia_blue-800 shadow"}>
                        <p className={"font-bold text-3xl text-center"}>{editKnowShowChartModal.code}: {editKnowShowChartModal.title}</p>
                        <div>
                            <p className={"relative font-bold mt-4"}>Standard Text:</p>
                            <p className={'pl-2'}>{editKnowShowChartModal.text}</p>
                        </div>

                        {(editKnowShowChartModal.supportingText !== "No supporting text.") && <><p className={"relative text-left font-bold mt-4"}>Supporting Text:</p><p className={'pl-2'}>{editKnowShowChartModal.supportingText}</p></>}

                    </div>
                    <div className={"bg-dutch_white-100 text-columbia_blue-800 h-full p-4 rounded cursor-black w-[69%] gap-4 divide-black overflow-y-auto shadow"}>
                        <p className={'h-fit'}>Double click a standard element to edit its text, click "Add" to create new elements.</p>
                        <div className={'grid grid-cols-3 w-full gap-4'}>
                            <div className={"flex flex-col grow align-top"}>
                                <p className={"font-bold text-2xl mb-2 border-b-2 border-slate-400"}>Recall:</p>
                                {knowShowElements.filter(item => (item.type === "Recall" && item.active)).map(element => (editOldElements.flatMap(item => item.id).includes(element._id.$oid)) ?
                                    <div>
                                        <div id={element._id.$oid} tabIndex={0} onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleEditorDoubleClick(element._id.$oid, element.title);
                                            }
                                        }} onDoubleClick={() => handleEditorDoubleClick(element._id.$oid, element.title)} className={"bg-burnt_sienna-300 p-1 rounded-t border-2 border-columbia_blue mt-2 line-through"}>{element.title}</div>
                                        <p className={'w-full bg-columbia_blue-800 text-dutch_white-400 text-sm'}>Update Title:</p>
                                        <div className={'bg-columbia_blue-800 flex'}>
                                            <textarea id={element._id.$oid} value={editOldElements.find(entry => entry.oldText === element.title).newText} className={"p-2 resize-none ml-8 bg-paynes_gray-100 rounded-tl border-burnt_sienna-300 border-2 text-xs grow"} onChange={handleEditOldElementChange}/>
                                        </div>
                                    </div>
                                    : (retireElements.includes(element._id.$oid)) ?
                                            <div id={element._id.$oid}
                                                 className={"relative bg-burnt_sienna-300 line-through p-1 rounded border-2 border-columbia_blue mt-2"}>
                                                <button onClick={() => markForRetire(element)}
                                                        className="absolute -top-4 -right-4 m-2 p-1 w-4 rounded-full bg-columbia_blue-700 text-dutch_white-400">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                                         viewBox="0 0 24 24">
                                                        <path fill="#fff"
                                                              d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                                    </svg>
                                                </button>
                                                {element.title}
                                            </div>
                                            :
                                            <div id={element._id.$oid}
                                                 tabIndex={0}
                                                 onKeyDown={(e) => {
                                                     if (e.key === 'Enter') {
                                                         e.preventDefault();
                                                         handleEditorDoubleClick(element._id.$oid, element.title);
                                                     }
                                                 }}
                                                 onDoubleClick={() => handleEditorDoubleClickRecall(element._id.$oid, element.title)}
                                                 className={"relative bg-columbia_blue-300 p-1 rounded border-2 border-columbia_blue mt-2 cursor-pointer hover:bg-columbia_blue-400"}>
                                                <button onClick={() => markForRetire(element)}
                                                        className="absolute -top-4 -right-4 m-2 p-1 w-4 rounded-full bg-burnt_sienna text-dutch_white-400">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                                         viewBox="0 0 24 24">
                                                        <path fill="#fff"
                                                              d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                                    </svg>
                                                </button>
                                                {element.title}
                                            </div>
                                    )}
                                {tempKnowShow.filter(item => (item.type === "Recall")).map(element => <div
                                    id={element.text} className={"flex justify-between my-1"}>
                                    <div
                                        className={"bg-columbia_blue-200 border-2 border-burnt_sienna-300 rounded-l w-full"}>
                                        <p className={"p-2"}>{element.title}</p></div>
                                    <button id={element.title} onClick={clearTempRecall}
                                            className={"bg-burnt_sienna-600 px-2 text-dutch_white-200 rounded-r"}>X
                                    </button>
                                </div>)}
                                {(editorState.includes("recall")) ?
                                    <div className={"relative flex flex-col"}>
                                        <textarea
                                            className={"p-2 bg-paynes_gray-100 rounded border-burnt_sienna-300 border-2 my-1"}
                                            onChange={handleNewRecallChange}/>
                                        <button
                                            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-columbia_blue-700 hover:bg-columbia_blue-600 text-dutch_white-200 rounded-xl p-2"
                                            onClick={handleIntegrateRecall}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="w-4 h-4"
                                            >
                                                <path d="M20 6L9 17l-5-5" />
                                            </svg>
                                        </button>
                                    </div>:
                                    <button className={"bg-columbia_blue-800 hover:bg-columbia_blue-700 cursor-pointer p-2 rounded mx-auto text-dutch_white-200 my-2"} onClick={handleAddRecall}>Add Recall Set</button>}
                            </div>
                            <div className={"flex flex-col"}>
                                <p className={"font-bold text-2xl mb-2 border-b-2 border-slate-400"}>Understanding:</p>
                                {knowShowElements.filter(item => (item.type === "Know" && item.active)).map(element => (editOldElements.flatMap(item => item.id).includes(element._id.$oid)) ?
                                    <div>
                                        <div id={element._id.$oid} tabIndex={0}
                                             onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleEditorDoubleClick(element._id.$oid, element.text);
                                            }
                                        }} onDoubleClick={() => handleEditorDoubleClick(element._id.$oid, element.text)} className={"bg-burnt_sienna-300 p-1 rounded-t border-2 border-columbia_blue mt-2 line-through"}>{element.text}</div>
                                        <p className={'w-full bg-columbia_blue-800 text-dutch_white-400 text-sm'}>Update Text:</p>
                                        <div className={'bg-columbia_blue-800 flex'}>
                                            <textarea id={element._id.$oid} value={editOldElements.find(entry => entry.oldText === element.text).newText} className={"p-2 resize-none ml-8 bg-paynes_gray-100 rounded-tl border-burnt_sienna-300 border-2 text-xs grow"} onChange={handleEditOldElementChange}/>
                                        </div>
                                    </div> : (retireElements.includes(element._id.$oid)) ?
                                        <div id={element._id.$oid}
                                             className={"relative bg-burnt_sienna-300 line-through p-1 rounded border-2 border-columbia_blue mt-2"}>
                                            <button onClick={() => markForRetire(element)}
                                                    className="absolute -top-4 -right-4 m-2 p-1 w-4 rounded-full bg-columbia_blue-700 text-dutch_white-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                                     viewBox="0 0 24 24">
                                                    <path fill="#fff"
                                                          d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                                </svg>
                                            </button>
                                            {element.text}
                                        </div>
                                        :
                                        <div id={element._id.$oid}
                                             tabIndex={0}
                                             onKeyDown={(e) => {
                                                 if (e.key === 'Enter') {
                                                     e.preventDefault();
                                                     handleEditorDoubleClick(element._id.$oid, element.text);
                                                 }
                                             }}
                                             onDoubleClick={() => handleEditorDoubleClick(element._id.$oid, element.text)}
                                             className={"relative bg-columbia_blue-300 p-1 rounded border-2 border-columbia_blue mt-2 cursor-pointer hover:bg-columbia_blue-400"}>
                                            <button onClick={() => markForRetire(element)}
                                                    className="absolute -top-4 -right-4 m-2 p-1 w-4 rounded-full bg-burnt_sienna text-dutch_white-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                                     viewBox="0 0 24 24">
                                                    <path fill="#fff"
                                                          d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                                </svg>
                                            </button>
                                            {element.text}
                                        </div>
                                )}
                                {tempKnowShow.filter(item => (item.type === "Know")).map(element => <div id={element.text} className={"flex justify-between my-1"}><div className={"bg-columbia_blue-200 border-2 border-burnt_sienna-300 rounded-l w-full"}><p className={"p-2"}>{element.text}</p></div><button id={element.text} onClick={clearTempKnowShow} className={"bg-burnt_sienna-600 px-2 text-dutch_white-200 rounded-r"}>X</button></div>)}
                                {(editorState.includes("know")) ?
                                    <div className={"relative flex flex-col"}>
                                        <textarea className={"p-2 bg-paynes_gray-100 rounded border-burnt_sienna-300 border-2 my-1"} onChange={handleNewKnowChange}/>
                                        <button
                                            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-columbia_blue-700 hover:bg-columbia_blue-600 text-dutch_white-200 rounded-xl p-2"
                                            onClick={handleIntegrateKnow}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="w-4 h-4"
                                            >
                                                <path d="M20 6L9 17l-5-5" />
                                            </svg>
                                        </button>
                                    </div>:
                                    <button className={"bg-columbia_blue-800 hover:bg-columbia_blue-700 cursor-pointer p-2 rounded mx-auto text-dutch_white-200 my-2"} onClick={handleAddKnow}>Add Understanding</button>}
                            </div>
                            <div className={"flex flex-col"}>
                                <p className={"font-bold text-2xl mb-2 border-b-2 border-slate-400"}>Apply:</p>
                                {knowShowElements.filter(item => (item.type === "Show" && item.active)).map(element => (editOldElements.flatMap(item => item.id).includes(element._id.$oid)) ?
                                    <div>
                                        <button id={element._id.$oid} onDoubleClick={() => handleEditorDoubleClick(element._id.$oid, element.text)} className={"bg-burnt_sienna-300 p-1 rounded-t border-2 border-columbia_blue mt-2 line-through"}>{element.text}</button>
                                        <p className={'w-full bg-columbia_blue-800 text-dutch_white-400 text-sm'}>Update Text:</p>
                                        <div className={'bg-columbia_blue-800 flex'}>
                                            <textarea id={element._id.$oid} value={editOldElements.find(entry => entry.oldText === element.text).newText} className={"p-2 resize-none ml-8 bg-paynes_gray-100 rounded-tl border-burnt_sienna-300 border-2 text-xs grow"} onChange={handleEditOldElementChange}/>
                                        </div>
                                    </div> : (retireElements.includes(element._id.$oid)) ?
                                        <div id={element._id.$oid}
                                             className={"relative bg-burnt_sienna-300 line-through p-1 rounded border-2 border-columbia_blue mt-2"}>
                                            <button onClick={() => markForRetire(element)}
                                                    className="absolute -top-4 -right-4 m-2 p-1 w-4 rounded-full bg-columbia_blue-700 text-dutch_white-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                                     viewBox="0 0 24 24">
                                                    <path fill="#fff"
                                                          d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                                </svg>
                                            </button>
                                            {element.text}
                                        </div>
                                        :
                                        <div tabIndex={0} id={element._id.$oid}
                                             onDoubleClick={() => handleEditorDoubleClick(element._id.$oid, element.text)}
                                             onKeyDown={(e) => {
                                                 if (e.key === 'Enter') {
                                                     e.preventDefault();
                                                     handleEditorDoubleClick(element._id.$oid, element.text);
                                                 }
                                             }}
                                             className={"relative bg-columbia_blue-300 p-1 rounded border-2 border-columbia_blue mt-2 cursor-pointer hover:bg-columbia_blue-400"}>
                                            <button onClick={() => markForRetire(element)}
                                                    className="absolute -top-4 -right-4 m-2 p-1 w-4 rounded-full bg-burnt_sienna text-dutch_white-400">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                                     viewBox="0 0 24 24">
                                                    <path fill="#fff"
                                                          d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                                </svg>
                                            </button>
                                            {element.text}
                                        </div>
                                )}
                                {tempKnowShow.filter(item => (item.type === "Show")).map(element => <div id={element.text} className={"flex justify-between my-1"}><div className={"bg-columbia_blue-200 rounded-l border-burnt_sienna-300 border-2 w-full"}><p className={"p-2"}>{element.text}</p></div><button id={element.text} onClick={clearTempKnowShow} className={"bg-burnt_sienna-600 px-2 text-dutch_white-200 rounded-r"}>X</button></div>)}
                                {(editorState.includes("show")) ?
                                    <div className={"relative flex flex-col"}>
                                        <textarea className={"p-2 bg-paynes_gray-100 rounded border-burnt_sienna-300 border-2 my-1"} onChange={handleNewShowChange}/>
                                        <button
                                            className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-columbia_blue-700 hover:bg-columbia_blue-600 text-dutch_white-200 rounded-xl p-2"
                                            onClick={handleIntegrateShow}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                className="w-4 h-4"
                                            >
                                                <path d="M20 6L9 17l-5-5" />
                                            </svg>
                                        </button>
                                    </div>:
                                    <button className={"bg-columbia_blue-800 hover:bg-columbia_blue-700 cursor-pointer p-2 rounded mx-auto text-dutch_white-200 my-2"} onClick={handleAddShow}>Add Application</button>}
                            </div>

                        </div>

                        {/*<div className={"flex flex-col col-span-2"}>*/}
                        {/*    <p className={"font-bold text-2xl mb-2 border-b-2 border-slate-400"}>Vocabulary:</p>*/}
                        {/*    <div className={"grid grid-cols-4 gap-2 mb-4"}>*/}
                        {/*        {(knowShowElements.filter(item => (item.type === "Vocab")).length > 0) && knowShowElements.filter(item => (item.type === "Vocab")).map(element => <div className={"bg-columbia_blue-300 p-1 border-2 border-columbia_blue rounded"}>{element.term}</div>)}*/}

                        {/*    </div>*/}
                        {/*    {tempKnowShow.filter(item => (item.type === "Vocab")).map(element => <div id={element.term} className={"flex justify-between my-1"}><div className={"bg-columbia_blue-200 rounded-l border-burnt_sienna-300 border-2 w-full"}><p className={"p-2"}>{element.term}: {element.definition}</p></div><button id={element.term} onClick={clearTempVocab} className={"bg-burnt_sienna-600 px-2 text-dutch_white-200 rounded-tr rounded-br"}>X</button></div>)}*/}
                        {/*    {(editorState.includes("vocab")) ? <div className={"flex flex-col"}><p className={"place-self-start pt-2 px-2 font-bold"}>Term:</p><textarea id={"termEdit"} onChange={handleNewVocabChange} className={"p-2 bg-paynes_gray-100 border-burnt_sienna-300 border-2"}></textarea><p className={"place-self-start pt-2 px-2 font-bold"}>Definition:</p><textarea id={"defEdit"} onChange={handleNewVocabChange} className={"p-2 bg-paynes_gray-100 border-burnt_sienna-300 border-2"}></textarea><div onClick={handleIntegrateVocab} className={'p-3 bg-columbia_blue-800 w-[30%] mx-auto hover:bg-columbia_blue-700 cursor-pointer mt-2 text-dutch_white-200 rounded'}>Add to Vocab</div></div>:<div className={"bg-columbia_blue-800 hover:bg-columbia_blue-700 cursor-pointer p-2 rounded mx-auto text-dutch_white-200 my-2"} onClick={handleAddVocab}>Add Vocabulary Word</div>}*/}



                        {/*</div>*/}

                    </div>
                </div>



                <button onClick={publishItem} className="relative self-center rounded bottom-2 align-middle m-2 p-2 bg-burnt_sienna text-dutch_white-100 font-bold px-10 text-xl hover:bg-burnt_sienna-400">
                    <p>Save Changes</p>
                </button>
            </div>


            {isLoading && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div>
            )}
        </div>
    );
}

export default EditKnowShowChartModal;