import React, {useState} from 'react';
import StandardSummaryBlock from "../../student/myData/standardSummaryBlock";
import StandardElementsModal from "../../student/myData/standardElementsModal";

function MTSSStudentDataSummaryModal({setStudentSummaryModal, studentData, standardSets, standardElements}) {

    const [selectedPrep, setSelectedPrep] = useState(null);
    const [standardElementsModal, setStandardElementsModal] = useState(null);

    const onClose = () => {
            setStudentSummaryModal(null)
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex-col z-10"} id="wrapper" onClick={handleClose}>
            <div className={"relative bg-dutch_white-200 m-4 rounded select-none p-2 max-h-[92%] min-w-[70%] max-w-[95%]"}>
                <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-300 z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={'overflow-y-auto max-h-full'}>
                    <p className={'select-none font-bold text-columbia_blue-800 text-4xl p-2'}>{studentData.firstName} {studentData.lastName}</p>
                    <div className={'flex gap-4 mx-auto w-fit'}>
                        {studentData.preps.map(prep => (prep === selectedPrep) ?
                            <div className={'flex w-fit h-fit my-auto rounded bg-columbia_blue-800 p-2 border-2 border-burnt_sienna'}>
                                <p className={'select-none my-auto font-bold text-dutch_white-400 text-2xl'}> {prep} </p>
                            </div>
                            :
                            <div onClick={() => setSelectedPrep(prep)} className={'flex w-fit h-fit my-auto rounded bg-platinum-700 hover:bg-columbia_blue-700 cursor-pointer p-2'}>
                                <p className={'select-none my-auto font-bold text-dutch_white-400 text-2xl'}> {prep} </p>
                            </div>
                        )}
                    </div>
                    {(selectedPrep) &&
                        <div className={'flex my-2'}>
                            <div className={'grow'}/>
                            <div className={'w-fit bg-paynes_gray-400 rounded shadow p-2'}>
                                <p className={'font-bold text-2xl text-columbia_blue-300'}>Current Data:</p>
                                <div className={'flex'}>
                                    <div className={'bg-columbia_blue-600 p-2 m-2 rounded'}>
                                        <p className={'text-columbia_blue-900 text-xl font-bold'}>{Math.floor(studentData.active[selectedPrep].filter(item => item.result === 0 || item.result === true).length / studentData.active[selectedPrep].length * 100)}%</p>
                                        <p>Correct</p>
                                    </div>
                                    <div className={'bg-burnt_sienna-400 p-2 m-2 rounded'}>
                                        <p className={'text-columbia_blue-900 text-xl font-bold'}>{Math.ceil(studentData.active[selectedPrep].filter(item => item.result === false || typeof item.result === 'number' && item.result > 0).length / studentData.active[selectedPrep].length * 100)}%</p>
                                        <p>Incorrect</p>
                                    </div>
                                    <div className={'bg-platinum-600 p-2 m-2 rounded'}>
                                        <p className={'text-columbia_blue-900 text-xl font-bold'}>{Math.ceil(studentData.active[selectedPrep].filter(item => item.result === 'pending').length / studentData.active[selectedPrep].length * 100)}%</p>
                                        <p>Pending</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'grow'}/>
                            {(window.screen.availWidth > 500) && <div className={'w-fit bg-paynes_gray-400 rounded shadow p-2'}>
                                <p className={'font-bold text-2xl text-columbia_blue-300'}>All Data:</p>
                                <div className={'flex'}>
                                    <div className={'bg-columbia_blue-600 p-2 m-2 rounded'}>
                                        <p className={'text-columbia_blue-900 text-xl font-bold'}>{Math.floor(studentData.active[selectedPrep].concat(studentData.archive[selectedPrep]).filter(item => item.result === 0 || item.result === true).length / studentData.active[selectedPrep].concat(studentData.archive[selectedPrep]).length * 100)}%</p>
                                        <p>Correct</p>
                                    </div>
                                    <div className={'bg-burnt_sienna-400 p-2 m-2 rounded'}>
                                        <p className={'text-columbia_blue-900 text-xl font-bold'}>{Math.ceil(studentData.active[selectedPrep].concat(studentData.archive[selectedPrep]).filter(item => item.result === false || typeof item.result === 'number' && item.result > 0).length / studentData.active[selectedPrep].concat(studentData.archive[selectedPrep]).length * 100)}%</p>
                                        <p>Incorrect</p>
                                    </div>
                                    <div className={'bg-platinum-600 p-2 m-2 rounded'}>
                                        <p className={'text-columbia_blue-900 text-xl font-bold'}>{Math.ceil(studentData.active[selectedPrep].concat(studentData.archive[selectedPrep]).filter(item => item.result === 'pending').length / studentData.active[selectedPrep].concat(studentData.archive[selectedPrep]).length * 100)}%</p>
                                        <p>Pending</p>
                                    </div>
                                </div>
                            </div>}
                            <div className={'grow'}/>

                        </div>

                    }
                    {(selectedPrep) &&
                        <div className={'grid md:grid-cols-2 lg:grid-cols-4 gap-2 p-2 bg-columbia_blue-800'}>
                            {standardSets.find(item => item.prepTitle === selectedPrep).standards.filter(item => studentData.active[selectedPrep].flatMap(entry => entry.standard).includes(item.standardCode)).map(item =>
                                <StandardSummaryBlock
                                    setStandardElementsModal={setStandardElementsModal}
                                    standard={item}
                                    standardData={studentData.active[selectedPrep].filter(entry => entry.standard === item.standardCode)}
                                />)}
                        </div>
                    }

                    {(standardElementsModal) && <StandardElementsModal standardElementsModal={standardElementsModal} setStandardElementsModal={setStandardElementsModal} knowShowElements={standardElements} />}
                </div>
            </div>
        </div>
    );
}

export default MTSSStudentDataSummaryModal;