import { React, useRef, useState, useEffect } from 'react';
import DOMPurify from "dompurify";

function SortingEntry({ questionModalContent, selectedAnswer, setSelectedAnswer }) {
    let [categories, setCategories] = useState(questionModalContent.categories[0].map(item => ({ title: item, response: [] })));
    let [selectedResponse, setSelectedResponse] = useState(null);

    let categoryRefs = useRef([]);
    let unselectedResponseRefs = useRef([]);

    useEffect(() => {
        if (selectedResponse !== null) {
            if (categoryRefs.current[0]) {
                categoryRefs.current[0].focus();
            }
            const handleKeyDown = (event) => {
                if (event.key === 'Tab') {
                    event.preventDefault();
                    const focusedIndex = categoryRefs.current.indexOf(document.activeElement);
                    const nextIndex = event.shiftKey ? focusedIndex - 1 : focusedIndex + 1;

                    if (nextIndex >= 0 && nextIndex < categoryRefs.current.length) {
                        categoryRefs.current[nextIndex].focus();
                    } else if (nextIndex >= categoryRefs.current.length) {
                        categoryRefs.current[0].focus();
                    } else {
                        categoryRefs.current[categoryRefs.current.length - 1].focus();
                    }
                }
            };

            document.addEventListener('keydown', handleKeyDown);
            return () => document.removeEventListener('keydown', handleKeyDown);
        }
    }, [selectedResponse]);

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    const order = ['text', 'image'];

    const handleSpaceClick = (index) => {
        let tempCategories = [...categories];
        tempCategories[index].response.push(selectedResponse);
        setCategories(tempCategories);
        setSelectedResponse(null);

        // If all items are assigned to a category, set the selected answer
        if (categories.flatMap(item => item.response).length === questionModalContent.categories[1].length) {
            setSelectedAnswer(categories);
        } else {
            setSelectedAnswer(null);

            // Find the first unselected response and focus on it
            const firstUnselectedButton = unselectedResponseRefs.current.find(button => button);
            if (firstUnselectedButton) {
                firstUnselectedButton.focus();
            }
        }
    };

    const handleClear = (entry, index) => {
        let tempCategories = [...categories];
        tempCategories[index].response = tempCategories[index].response.filter(item => item !== entry);
        setCategories(tempCategories);
        setSelectedAnswer(null);
    };

    return (
        <div className={'p-2 bg-columbia_blue-300'}>
            {questionModalContent.imageURL && (
                <img
                    src={questionModalContent.imageURL}
                    className={"rounded w-80 mx-auto mb-2 p-2 bg-dutch_white-300 shadow border-dutch_white-600 border-2"}
                />
            )}
            <div className={"bg-dutch_white-300 text-lg p-4 rounded text-dutch_white-800 border-dutch_white-600 border-2 shadow"}>
                <div dangerouslySetInnerHTML={renderHtml(questionModalContent.prompt)} />
            </div>
            <div className={'flex flex-col'}>
                <div className={'flex gap-2 w-full p-2 overflow-x-auto'}>
                    <div className={'grow'} />
                    {categories.map((item, index) => (
                        <div key={index} className={'bg-paynes_gray-600 rounded shadow my-2 px-4 flex flex-col py-2'}>
                            <div
                                className={"bg-columbia_blue-700 w-[200px] text-dutch_white-100 p-2 border-gray-500 border-1 rounded-t text-xl h-fit mx-auto"}>
                                <div dangerouslySetInnerHTML={renderHtml(item.title)} />
                            </div>
                            {selectedResponse ? (
                                <button
                                    ref={el => categoryRefs.current[index] = el}
                                    tabIndex={0}
                                    className={'flex flex-wrap gap-2 w-[200px] shadow-inner bg-dutch_white-200 hover:bg-dutch_white-300 rounded-b min-h-[50px] border-2 border-burnt_sienna max-h-[250px] overflow-y-auto'}
                                    onClick={() => handleSpaceClick(index)}
                                >
                                    <div className={'w-full h-full flex flex-wrap cursor-pointer'}>
                                        {item.response.map((entry, entryIndex) =>
                                            entry.type === 'text' ? (
                                                <div
                                                    key={entryIndex}
                                                    className={"bg-dutch_white-300 p-2 border-1 my-1 rounded w-fit shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer ml-2"}>
                                                    <div className={'text-black'} dangerouslySetInnerHTML={renderHtml(entry.content)} />
                                                </div>
                                            ) : (
                                                <div
                                                    key={entryIndex}
                                                    className={"bg-dutch_white-300 p-2 border-1 my-1 rounded w-fit shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer ml-2"}>
                                                    <img draggable={false} src={entry.content} className={"rounded max-h-[100px]"} />
                                                </div>
                                            )
                                        )}
                                    </div>
                                </button>
                            ) : (
                                <div
                                    className={'flex flex-wrap gap-2 w-[200px] shadow-inner bg-dutch_white-200 border-2 border-dutch_white-200 rounded-b min-h-[50px] max-h-[250px] overflow-y-auto'}>
                                    {item.response.map((entry, entryIndex) =>
                                        entry.type === 'text' ? (
                                            <button
                                                key={entryIndex}
                                                className={"bg-dutch_white-300 p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer hover:bg-burnt_sienna-400 ml-2"}
                                                onClick={() => handleClear(entry, index)}
                                            >
                                                <div className={'text-black'} dangerouslySetInnerHTML={renderHtml(entry.content)} />
                                            </button>
                                        ) : (
                                            <button
                                                key={entryIndex}
                                                className={"bg-dutch_white-300 p-2 border-1 my-1 rounded w-fit h-fit shadow-inner shadow-3xl px-2 border-columbia_blue-600 border-2 cursor-pointer hover:bg-burnt_sienna-400 ml-2"}
                                                onClick={() => handleClear(entry, index)}
                                            >
                                                <img draggable={false} src={entry.content} className={"rounded max-h-[100px]"} />
                                            </button>
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                    <div className={'grow'} />
                </div>
                <div className={'flex flex-wrap gap-2 w-4/5 mx-auto p-2'}>
                    {questionModalContent.categories[1]
                        .sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type))
                        .filter(item => !categories.flatMap(category => category.response).includes(item))
                        .map((entry, index) => (
                            <button
                                key={index}
                                ref={el => unselectedResponseRefs.current[index] = el}
                                className={
                                    entry === selectedResponse
                                        ? "bg-columbia_blue-600 text-dutch_white-100 p-2 border-dutch_white-200 border-2 my-3 h-fit rounded w-fit cursor-pointer hover:bg-columbia_blue-600 mx-auto"
                                        : "bg-dutch_white-300 text-dutch_white-900 p-2 border-platinum-700 border-2 my-3 rounded w-fit h-fit cursor-pointer hover:bg-columbia_blue-600 mx-auto"
                                }
                                onClick={() => setSelectedResponse(entry)}
                            >
                                {entry.type === 'text' && <div dangerouslySetInnerHTML={renderHtml(entry.content)} />}
                                {entry.type === 'image' && <img draggable={false} src={entry.content} className={"rounded max-h-[100px]"} />}
                            </button>
                        ))}
                </div>
            </div>
        </div>
    );
}

export default SortingEntry;