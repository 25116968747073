import React, {useContext, useEffect, useState} from 'react';
import UserContext from "../../context/userContext";
import InteractiveSearch from "../../components/interactiveSearch";
import StandardSelectionBar from "./standardSelectionBar";
import CreateItemModal from "./createItemModal";
import ViewAssessmentItemsModal from "./viewAssessmentItemsModal";
import {useNavigate} from "react-router-dom";
import SupplementalStandardModal from "./supplementalStandardModal";
import TeacherNavBar from "../teacherNavBar";
import EditKnowShowChartModal from "./editKnowShowChartModal";
import AddRecallElementsModal from "./addRecallElementsModal";
import DOMPurify from "dompurify";
import AssignRecallModal from "./assignRecallModal";
import getWithAuth from "../../context/getWithAuth";
import Loader from "../../components/loader";
import RecallEditPromptModal from "./recallEditPromptModal";
import ToSModal from "../../components/tosModal";


function Planning() {
    const [standardSets, setStandardSets] = useState(null);
    const [rosters, setRosters] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);
    const navigate = useNavigate();


    const [selectedStandardSet, setSelectedStandardSet] = useState(null);
    const [selectedStandard, setSelectedStandard] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const [createItemModal, setCreateItemModal] = useState(null);
    const [viewAssessmentItemsModal, setViewAssessmentItemsModal] = useState(null);
    const [supplementalStandardModal, setSupplementalStandardModal] = useState(null);
    const [editKnowShowChartModal, setEditKnowShowChartModal] = useState(null);
    const [addRecallElementsModal, setAddRecallElementsModal] = useState(null);
    const [recallEditPromptModal, setRecallEditPromptModal] = useState(null);
    const [assignRecallModal, setAssignRecallModal] = useState(null);

    const [isSidenavOpen, setSidenavOpen] = useState(false);

    const toggleSidenav = () => setSidenavOpen(prev => !prev);

    const user = useContext(UserContext);

    useEffect(() => {
        if (createItemModal || viewAssessmentItemsModal || editKnowShowChartModal || addRecallElementsModal || recallEditPromptModal || assignRecallModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';}
    }, [createItemModal, viewAssessmentItemsModal, editKnowShowChartModal, addRecallElementsModal, recallEditPromptModal, assignRecallModal])

    const fetchStandardSets = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getStandardSets`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setStandardSets(r);
                        if (selectedStandardSet) {
                            let newStandardSet = r.find(item => (item.title === selectedStandardSet.title));
                            await setSelectedStandard(null);
                            await setSearchValue(null);
                            await setSelectedStandardSet(newStandardSet);
                        }
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchRosters = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/logistics/getRosters`, user.userAttributes['custom:school'], {userId: user.userContext._id.$oid})
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getKnowShow`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/planning/getAssessmentItems`, user.userAttributes['custom:school'], {preps: encodeURIComponent(JSON.stringify(user.userContext.preps))})
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const handleChangeStandardSet = async (event) => {
        let newStandardSet = standardSets.find(item => (item.title === event.target.value));
        await setSelectedStandard(null);
        await setSearchValue(null);
        await setSelectedStandardSet(newStandardSet);
    }

    const handleSearchChange = (e) => {
        if (selectedStandardSet) {
            setSearchValue(selectedStandardSet.standards.filter(standard => (standard.standardCode.toLowerCase().includes(e.target.value.toLowerCase()) || standard.title.toLowerCase().includes(e.target.value.toLowerCase()) || standard.text.toLowerCase().includes(e.target.value.toLowerCase()) || standard.supportingText.toLowerCase().includes(e.target.value.toLowerCase()))));
        } else {
            return null;
        }
    }

    const handleStandardClick = (standard) => {
        setSelectedStandard(standard);
        toggleSidenav()
    }

    useEffect(() => {
        fetchStandardSets();
        fetchRosters();
        fetchKnowShowCharts(user);
        fetchAssessmentItems(user);
    }, [user.userContext])

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        const plainText = new DOMParser().parseFromString(sanitizedHtml, 'text/html').body.textContent;
        return {
            __html: sanitizedHtml,
            plainText: plainText
        };
    };


    if (!user.userContext) {
        return <div className={'h-screen flex-col overflow-hidden'}>
            <TeacherNavBar/>
            <Loader message={'Fetching user data'}/>
        </div>
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (user.userContext.role === 'student') {
        navigate('/home')
    } else if (!standardSets || !knowShowCharts || !assessmentItems) {
        return <div className={'h-screen flex-col overflow-hidden'}>
            <TeacherNavBar />
            <Loader message={'Fetching relevant standard sets'}/>
        </div>
    } else {
        return (
            <div className={'min-h-screen flex-col'}>
                <TeacherNavBar />
                <div className="relative min-h-screen flex flex-col">
                    {/* Backdrop Overlay */}
                    {isSidenavOpen && (
                        <div
                            className="fixed inset-0 bg-black opacity-50 z-10"
                            onClick={toggleSidenav}
                        />
                    )}

                    {/* Sidenav */}
                    <div
                        className={`fixed top-0 left-0 h-full overflow-y-auto w-[50%] bg-columbia_blue-300 z-20 shadow-xl transform transition-transform duration-300 ${
                            isSidenavOpen ? 'translate-x-0' : '-translate-x-full'
                        }`}
                    >
                        <div className="flex items-center justify-between p-4 border-b-2 border-columbia_blue-800">
                            <h2 className="text-xl font-bold text-columbia_blue-800">Standards</h2>
                            <select
                                className="rounded p-4 bg-columbia_blue-800 text-dutch_white-300 font-bold"
                                onChange={handleChangeStandardSet}
                                defaultValue=""
                            >
                                <option disabled value="">
                                    Select a Standard Set
                                </option>
                                {standardSets
                                    .filter(item => user.userContext.preps.includes(item.prepTitle))
                                    .sort((a, b) => a.prepTitle.toLowerCase().localeCompare(b.prepTitle.toLowerCase()))
                                    .map(standardSet => (
                                        <option key={standardSet.title} value={standardSet.title}>
                                            {standardSet.prepTitle}
                                        </option>
                                    ))}
                            </select>
                        </div>
                        <div className="p-2">
                            <InteractiveSearch placeholder="Search Standards" handleChange={handleSearchChange}/>
                        </div>
                        {(selectedStandardSet &&
                            <div className="grow">
                                {searchValue ? (
                                    searchValue.map(standard => (
                                        <StandardSelectionBar
                                            key={standard.standardCode}
                                            standard={standard}
                                            standardCode={standard.standardCode}
                                            selectedStandard={selectedStandard}
                                            setSelectedStandard={setSelectedStandard}
                                            knowShow={knowShowCharts.filter(
                                                item => item.standard === standard.standardCode && item.prep === selectedStandardSet.prepTitle
                                            )}
                                            handleStandardClick={handleStandardClick}

                                        />
                                    ))
                                ) : (
                                    selectedStandardSet.standards.map(standard => (
                                        <StandardSelectionBar
                                            key={standard.standardCode}
                                            standard={standard}
                                            standardCode={standard.standardCode}
                                            selectedStandard={selectedStandard}
                                            setSelectedStandard={setSelectedStandard}
                                            knowShow={knowShowCharts.filter(
                                                item => item.standard === standard.standardCode && item.prep === selectedStandardSet.prepTitle
                                            )}
                                            handleStandardClick={handleStandardClick}
                                        />
                                    ))
                                )}
                                <div
                                    className="flex flex-col py-2 px-4 w-[80%] border-2 border-columbia_blue-800 my-2 bg-columbia_blue-800 hover:bg-columbia_blue-700 rounded border-opacity-30 mx-auto cursor-pointer shadow text-center"
                                    onClick={() => setSupplementalStandardModal(true)}
                                >
                                    <p className="font-bold text-dutch_white-400">Add Supplemental Standard</p>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Main Content */}
                    <div className={`grow flex flex-col overflow-x-clip`}>
                        <div className={'flex grow'}>
                            <div className={'flex flex-col'}>
                                <button className={'bg-paynes_gray-800 hover:bg-paynes_gray-700 -mt-2 grow w-9'}
                                        onClick={toggleSidenav}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        className="mx-1"
                                        strokeWidth={10}
                                        viewBox="0 0 16 16"
                                        style={{fill: '#f6f0e0', display: 'inline', zIndex: 10}}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1.646 8.354a.5.5 0 0 1 0-.708l4.5-4.5a.5.5 0 0 1 .708.708L2.707 8l4.147 4.146a.5.5 0 0 1-.708.708l-4.5-4.5z"
                                        />
                                    </svg>
                                    <p className={'rotate-90 text-dutch_white-200 flex items-center mt-8 mb-16'}>
                                        Standards
                                    </p>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="12"
                                        height="12"
                                        className="mx-1"
                                        viewBox="0 0 16 16"
                                        style={{fill: '#f6f0e0', display: 'inline', zIndex: 10}}
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M1.646 8.354a.5.5 0 0 1 0-.708l4.5-4.5a.5.5 0 0 1 .708.708L2.707 8l4.147 4.146a.5.5 0 0 1-.708.708l-4.5-4.5z"
                                        />
                                    </svg>
                                </button>
                            </div>

                            <div className={'h-full w-[95%] mx-auto my-4 relative'}>
                                {(selectedStandardSet && knowShowCharts) && <>
                                    <div className={"mx-2 w-full"}>
                                        <div className={"w-full mx-1]"}>
                                            {(selectedStandardSet && selectedStandard) ?
                                                // <StandardOverviewPanel selectedStandardSet={selectedStandardSet} standard={selectedStandard} standardCode={selectedStandard.standardCode} user={user} knowShow={knowShowCharts.filter(element => element.standard === selectedStandard.standardCode)} assessmentItems={assessmentItems.filter(element => element.standard === selectedStandard.standardCode)} setCreateItemModal={setCreateItemModal} setViewAssessmentItemsModal={setViewAssessmentItemsModal} resetFunction={fetchKnowShowCharts}/>
                                                <div
                                                    className={'max-w-[90%] flex flex-col h-full bg-dutch_white-100 border-2 border-columbia_blue-700 rounded shadow mx-auto'}>
                                                    <p className={"text-xl font-bold text-columbia_blue-800 my-2"}>{selectedStandard.title}</p>
                                                    <p className={'text-columbia_blue-800 mb-2 text-xs px-2'}>{selectedStandard.text}</p>
                                                    <div className={'grid grid-cols-6 gap-2 h-full p-2'}>
                                                        <div className={'col-span-6'}>
                                                            <p title={'Recall sets are for organizing the most fundamental information in a standard. These sets are uniquely built only for matching style questions.'}
                                                               className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Recall</p>
                                                            {/*{(knowShowCharts.filter(item => item.standard === selectedStandard.standardCode).length === 0) &&*/}
                                                            {/*    <p className={'mt-4 text-columbia_blue-800 select-none'}>Looks like*/}
                                                            {/*        this is standard has not been broken down yet! Click edit below*/}
                                                            {/*        to get started. </p>}*/}

                                                            {(knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                                <p className={'mt-4 col-span-6 text-xs text-columbia_blue-800 select-none'}>There's
                                                                    nothing here! Click Edit below to get to work on
                                                                    this
                                                                    standard's Recall section!</p> :
                                                                <div className={'flex'}>
                                                                    <div className={'flex flex-col w-1/2 p-2'}>
                                                                        {knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map((element, index) =>
                                                                            (index % 2 === 0) &&
                                                                            <div key={element._id.$oid}
                                                                                 className={'bg-columbia_blue-300 col-span-3 rounded border-2 border-columbia_blue-700 px-2 flex flex-col shadow m-2'}>
                                                                                <div className={'flex flex-col h-full'}>
                                                                                    <p className={'text-center font-bold p-1'}>{element.title}</p>
                                                                                    <div
                                                                                        className={'text-xs rounded-t bg-columbia_blue-500 p-1'}
                                                                                        dangerouslySetInnerHTML={renderHtml(element.prompt)}/>
                                                                                    <div
                                                                                        className={'bg-paynes_gray h-full rounded-b flex flex-wrap p-2 mb-1'}>
                                                                                        {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.map(item =>
                                                                                            <div
                                                                                                title={renderHtml(item.match.content).plainText}
                                                                                                className={'bg-columbia_blue-400 text-xs m-1 p-1 h-fit rounded grow'}
                                                                                                dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>)}
                                                                                    </div>
                                                                                    <div
                                                                                        className={'flex gap-2 mx-auto mb-1'}>
                                                                                        <div
                                                                                            className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                                            onClick={() => setAddRecallElementsModal(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active'))}>
                                                                                            View / Add Items
                                                                                        </div>
                                                                                        <div
                                                                                            className={'text-xs text-platinum-100 rounded bg-paynes_gray-700 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-paynes_gray-800 cursor-pointer'}
                                                                                            onClick={() => setRecallEditPromptModal({
                                                                                                oldPrompt: element.prompt,
                                                                                                assessmentItemId: element._id.$oid
                                                                                            })}>
                                                                                            Edit Prompt
                                                                                        </div>

                                                                                        {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && (assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.length > 1) ?
                                                                                            <div
                                                                                                className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-900 cursor-pointer'}
                                                                                                onClick={() => setAssignRecallModal(assessmentItems.find(item => item.knowShowId === element._id.$oid))}>Assign
                                                                                                Items</div>
                                                                                            : <div
                                                                                                className={'text-xs text-platinum-100 rounded bg-platinum-700 p-1 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                                Items</div>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>)}
                                                                    </div>

                                                                    <div className={'flex flex-col w-1/2 p-2'}>
                                                                        {knowShowCharts.filter(item => item.type === 'Recall' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map((element, index) =>
                                                                            (index % 2 === 1) &&
                                                                            <div key={element._id.$oid}
                                                                                 className={'bg-columbia_blue-300 col-span-3 rounded border-2 border-columbia_blue-700 px-2 flex flex-col shadow m-2'}>
                                                                                <div className={'flex flex-col h-full'}>
                                                                                    <p className={'text-center font-bold p-1'}>{element.title}</p>
                                                                                    <div
                                                                                        className={'text-xs rounded-t bg-columbia_blue-500 p-1'}
                                                                                        dangerouslySetInnerHTML={renderHtml(element.prompt)}/>
                                                                                    <div
                                                                                        className={'bg-paynes_gray h-full rounded-b flex flex-wrap p-2 mb-1'}>
                                                                                        {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.map(item =>
                                                                                            <div
                                                                                                title={renderHtml(item.match.content).plainText}
                                                                                                className={'bg-columbia_blue-400 text-xs m-1 p-1 h-fit rounded grow'}
                                                                                                dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>)}
                                                                                    </div>
                                                                                    <div
                                                                                        className={'flex gap-2 mx-auto mb-1'}>
                                                                                        <div
                                                                                            className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                                            onClick={() => setAddRecallElementsModal(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active'))}>
                                                                                            View / Add Items
                                                                                        </div>
                                                                                        <div
                                                                                            className={'text-xs text-platinum-100 rounded bg-paynes_gray-700 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-paynes_gray-800 cursor-pointer'}
                                                                                            onClick={() => setRecallEditPromptModal({
                                                                                                oldPrompt: element.prompt,
                                                                                                assessmentItemId: element._id.$oid
                                                                                            })}>
                                                                                            Edit Prompt
                                                                                        </div>

                                                                                        {(assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active')) && (assessmentItems.find(item => item.knowShowId === element._id.$oid && item.status === 'active').recallItems.length > 1) ?
                                                                                            <div
                                                                                                className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-1 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-900 cursor-pointer'}
                                                                                                onClick={() => setAssignRecallModal(assessmentItems.find(item => item.knowShowId === element._id.$oid))}>Assign
                                                                                                Items</div>
                                                                                            : <div
                                                                                                className={'text-xs text-platinum-500 rounded bg-platinum-700 p-1 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                                Items</div>}
                                                                                    </div>
                                                                                </div>
                                                                            </div>)}
                                                                    </div>

                                                                </div>
                                                            }
                                                        </div>
                                                        <div className={'col-span-3'}>
                                                            <p title={'Understanding elements are aimed toward complex information related to categorization or relationships among parts of a standard. These are for areas of knowledge that require more deliberate assessment and should take the form of declarative statements of fact.'}
                                                               className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Understand</p>
                                                            {(knowShowCharts.filter(item => item.type === 'Know' && item.standard === selectedStandard.standardCode && item.active && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                                <p className={'mt-4 text-xs col-span-6 text-columbia_blue-800 select-none'}>There's
                                                                    nothing here! Click Edit below to get to work on
                                                                    this
                                                                    standard's Understand section!</p> :
                                                                knowShowCharts.filter(item => item.type === 'Know' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map(element =>
                                                                    <div key={element._id.$oid}
                                                                         className={'bg-columbia_blue-300 col-span-2 rounded border-2 border-columbia_blue-700 p-2 flex flex-col shadow m-2'}>
                                                                        <div className={'flex flex-col h-full'}>
                                                                            <p className={'text-left text-sm p-1'}>{element.text}</p>
                                                                            <div className={'grow'}/>
                                                                            <div className={'flex gap-2 mx-auto'}>
                                                                                <div
                                                                                    className={'text-xs text-platinum-500 rounded bg-burnt_sienna-600 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                                    onClick={() => setCreateItemModal({
                                                                                        ...element,
                                                                                        questionType: 'Traditional MC'
                                                                                    })}>Create
                                                                                    Item
                                                                                </div>
                                                                                {(assessmentItems && assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length > 0) ?
                                                                                    <div
                                                                                        className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-700 cursor-pointer'}
                                                                                        onClick={() => setViewAssessmentItemsModal(assessmentItems.filter(item => item.knowShowId === element._id.$oid).filter(item => item.status === 'active'))}>View
                                                                                        Items: {assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length}</div>
                                                                                    : <div
                                                                                        className={'text-xs text-platinum-100 rounded bg-platinum-700 p-2 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                        Items</div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>)}

                                                        </div>
                                                        <div className={'col-span-3'}>
                                                            <p title={'Apply elements represent the skills that students learn throughout a standard and demonstrate through assessment items. These should take the form of "I can..." statements.'}
                                                               className={'border-b-2 font-bold text-xl text-columbia_blue-800'}>Apply</p>
                                                            {(knowShowCharts.filter(item => item.type === 'Show' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).length === 0) ?
                                                                <p className={'mt-4 text-xs col-span-6 text-columbia_blue-800 select-none'}>There's
                                                                    nothing here! Click Edit below to get to work on
                                                                    this
                                                                    standard's Understand section!</p> :
                                                                knowShowCharts.filter(item => item.type === 'Show' && item.active && item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle).map(element =>
                                                                    <div key={element._id.$oid}
                                                                         className={'bg-columbia_blue-300 col-span-2 rounded border-2 border-columbia_blue-700 p-2 flex flex-col shadow m-2'}>
                                                                        <div className={'flex flex-col h-full'}>
                                                                            <p className={'text-left text-sm p-1'}>{element.text}</p>
                                                                            <div className={'grow'}/>
                                                                            <div className={'flex gap-2 mx-auto'}>
                                                                                <div
                                                                                    className={'text-xs text-platinum-100 rounded bg-burnt_sienna-600 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-burnt_sienna-700 cursor-pointer'}
                                                                                    onClick={() => setCreateItemModal({
                                                                                        ...element,
                                                                                        questionType: 'Traditional MC'
                                                                                    })}>Create
                                                                                    Item
                                                                                </div>
                                                                                {(assessmentItems && assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length > 0) ?
                                                                                    <div
                                                                                        className={'text-xs text-platinum-100 rounded bg-columbia_blue-800 p-2 select-none border-2 border-platinum-500 border-opacity-50 hover:bg-columbia_blue-700 cursor-pointer'}
                                                                                        onClick={() => setViewAssessmentItemsModal(assessmentItems.filter(item => item.knowShowId === element._id.$oid).filter(item => item.status === 'active'))}>View
                                                                                        Items: {assessmentItems.filter(item => item.knowShowId === element._id.$oid && item.status === 'active').length}</div>
                                                                                    : <div
                                                                                        className={'text-xs text-platinum-100 rounded bg-platinum-700 p-2 select-none border-2 border-platinum-500 border-opacity-50'}>Assign
                                                                                        Items</div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>)}

                                                        </div>
                                                    </div>


                                                    <div className={'grow'}></div>
                                                    <button onClick={() => setEditKnowShowChartModal(selectedStandard)}
                                                            className={'w-full bg-paynes_gray-500 hover:bg-paynes_gray-400 shadow-inner mt-1 py-1 text-dutch_white-200 select-none cursor-pointer'}>Edit
                                                        Standard Elements
                                                    </button>
                                                </div>

                                                :  <></>}
                                        </div>
                                    </div>
                                </>}
                                {(!selectedStandard) && <div className={'flex pl-10 pr-20 w-full mx-auto'}>
                                    <button onClick={toggleSidenav}
                                            className={'w-[30%] m-4 bg-dutch_white-200 hover:bg-dutch_white-300 hover:shadow-lg rounded shadow pt-8'}>
                                        <img
                                            src={'https://ucarecdn.com/2e602e15-1786-409a-86ee-a8069e704615/Planning.png'}
                                            alt={'Clipboard and writing pencil clipart'}
                                            className={'w-[50%] mx-auto'}
                                        />
                                        <ul className={'text-left text-sm w-[70%] mx-auto mt-8 list-outside list-["-_"]'}>
                                            <li className={'mb-4'}>Break down your standards into teachable standard
                                                elements
                                            </li>
                                            <li className={'mb-4'}>Draft your assessment questions</li>
                                            <li className={'mb-4'}>Collaborate with the other teachers that share your
                                                preps
                                            </li>
                                        </ul>
                                    </button>
                                    <button onClick={() => {
                                        navigate('/logistics')
                                    }}
                                            className={'w-[30%] m-4 bg-burnt_sienna-100 hover:bg-burnt_sienna-200 hover:shadow-lg rounded shadow pt-8'}>
                                        <img
                                            src={'https://ucarecdn.com/b08ad6dc-6e6d-4253-8f9a-0ca227467550/Logistics.png'}
                                            alt={'Calendar of events clip-art'}
                                            className={'w-[50%] mx-auto'}
                                        />
                                        <ul className={'text-left text-sm w-[70%] mx-auto mt-8 list-outside list-["-_"]'}>
                                            <li className={'mb-4'}>Arrange your standard elements into unit plans</li>
                                            <li className={'mb-4'}>Create and edit rosters for your classes</li>
                                            <li className={'mb-4'}>Create data driven seating charts to maximize student
                                                collaboration opportunities
                                            </li>
                                            <li className={'mb-4'}>Review individual level student data for
                                                participation and understanding of specific standard elements
                                            </li>
                                        </ul>

                                    </button>
                                    <button onClick={() => {
                                        navigate('/data')
                                    }}
                                            className={'w-[30%] m-4 bg-columbia_blue-100 hover:bg-columbia_blue-200 hover:shadow-lg rounded shadow pt-8'}>
                                        <img src={'https://ucarecdn.com/1849616d-f4d6-4acd-9887-4e860a11fcac/Data.png'}
                                             alt={'Various charts and graphs clip-art'} className={'w-[50%] mx-auto'}
                                        />
                                        <ul className={'text-left text-sm w-[70%] mx-auto mt-8 list-outside list-["-_"]'}>
                                            <li className={'mb-4'}>Review balcony level data</li>
                                            <li className={'mb-4'}>Compare student understanding and instructional
                                                quality across multiple rosters and preps
                                            </li>
                                            <li className={'mb-4'}>Identify groups of students with shared
                                                misconceptions </li>
                                        </ul>
                                    </button>

                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {(createItemModal) && <CreateItemModal user={user} createItemModal={createItemModal}
                                                       setCreateItemModal={setCreateItemModal}
                                                       assessmentItems={assessmentItems}
                                                       setAssessmentItems={setAssessmentItems}
                                                       resetFunction={fetchAssessmentItems}/>}
                {(viewAssessmentItemsModal) && <ViewAssessmentItemsModal user={user} rosters={rosters}
                                                                         viewAssessmentItemsModal={viewAssessmentItemsModal}
                                                                         setViewAssessmentItemsModal={setViewAssessmentItemsModal}
                                                                         resetFunction={fetchAssessmentItems}/>}
                {(assignRecallModal) && <AssignRecallModal user={user} assignRecallModal={assignRecallModal}
                                                           setAssignRecallModal={setAssignRecallModal}
                                                           rosters={rosters}/>}
                {(supplementalStandardModal) &&
                    <SupplementalStandardModal setSupplementalStandardModal={setSupplementalStandardModal}
                                               school={user.userAttributes['custom:school']}
                                               standardSet={selectedStandardSet} resetFunction={fetchStandardSets}/>}
                {(editKnowShowChartModal) && <EditKnowShowChartModal editKnowShowChartModal={editKnowShowChartModal}
                                                                     selectedStandardSet={selectedStandardSet}
                                                                     setKnowShowCharts={setKnowShowCharts}
                                                                     setAssessmentItems={setAssessmentItems}
                                                                     setEditKnowShowChartModal={setEditKnowShowChartModal}
                                                                     user={user}
                                                                     standardCode={selectedStandard.standardCode}
                                                                     knowShowElements={knowShowCharts.filter(item => item.standard === selectedStandard.standardCode && item.prep === selectedStandardSet.prepTitle)}/>}
                {(addRecallElementsModal) &&
                    <AddRecallElementsModal user={user} addRecallElementsModal={addRecallElementsModal} setAddRecallElementsModal={setAddRecallElementsModal} assessmentItems={assessmentItems} setAssessmentItems={setAssessmentItems} resetFunction={fetchAssessmentItems}/>}
                {(recallEditPromptModal) && <RecallEditPromptModal setRecallEditPromptModal={setRecallEditPromptModal} recallEditPromptModal={recallEditPromptModal} user={user} getAssessmentItems={fetchAssessmentItems} />}
            </div>
        );
    }}

export default Planning;