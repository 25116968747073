import React from 'react';
import DOMPurify from "dompurify";
import putWithAuth from "../../context/putWithAuth";

function ChangeUnitModal({user, prep, setChangeUnitModal, unitPlans}) {
    const onClose = () => {
        setChangeUnitModal(null)
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }
    const submitChangeUnit = (unit) => {
        user.userContext.currentUnits[prep] = unit._id.$oid;
        putWithAuth('/logistics/putChangeUnit', user.userAttributes['custom:school'], {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: {
                userId: user.userContext._id.$oid,
                prep: unit.prep,
                unit: unit._id.$oid
            },
        })
        setChangeUnitModal(null);
    }

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
            <div className={"flex flex-col"}>
                <div className={"relative bg-dutch_white-400 p-2 m-4 rounded max-w-[1200px]"}>
                    <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                        </svg>
                    </button>
                    <div className={'p-2 bg-columbia_blue-800 flex flex-wrap max-h-full min-h-[200px] max-w-full'}>
                        {unitPlans.filter(unit => unit.prep === prep).map(plan =>
                            <div onClick={() => submitChangeUnit(plan)} className={'rounded bg-dutch_white hover:bg-dutch_white-600 cursor-pointer flex flex-col p-4 m-2 h-fit'}>
                                <div className={'text-lg font-bold text-columbia_blue-800'} dangerouslySetInnerHTML={renderHtml(plan.title)}/>
                                <p className={'text-columbia_blue-800'}>{plan.duration} weeks</p>
                                <div className={'flex gap-2'}>
                                    <p className={'my-auto text-columbia_blue-800'}>Standards: </p>
                                    {plan.standards.map(standard =>
                                        <div title={standard.title} className={'p-1 bg-columbia_blue-700 rounded'}>
                                            <p  className={'text-dutch_white-400'}>{standard.code}</p>
                                        </div>)}
                                </div>
                            </div>)}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ChangeUnitModal;