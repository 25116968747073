import React, {useState} from 'react';
import getWithAuth from "../../context/getWithAuth";
import postWithAuth from "../../context/postWithAuth";

function NewRosterModal({user, getRosters, newRosterModal, setNewRosterModal}) {
    const [title, setTitle] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const onClose = () => {
        setNewRosterModal(null)
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    }

    const handleRosterSubmit = () => {
        setIsLoading(true);
        let submission = {prep: newRosterModal, school: user.userContext.school, title: title, instructorID: user.userContext._id.$oid}
        let url = new URL(`${process.env.REACT_APP_API_BASE}/${process.env.REACT_APP_API_STAGE}/logistics/postNewRoster`)
        url.searchParams.append('school', user.userAttributes["custom:school"]);
        postWithAuth('/logistics/postNewRoster', user.userAttributes["custom:school"], {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: submission
        })
            .then(async response => {
                if (response.ok) {
                    await getRosters();
                    setNewRosterModal(null);
                } else {
                    console.log(response.error)
                    setIsLoading(false)
                }
            })}


    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
            <div className={"flex flex-col"}>
                <div className={"relative bg-dutch_white-400 p-2 m-4 rounded p-2 max-w-[1200px]"}>
                    <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-dutch_white-400">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                        </svg>
                    </button>
                    <div className={"bg-columbia_blue-500 p-4 text-black cursor-black rounded"}>
                        <p className={'font-bold text-columbia_blue-800 text-2xl'}>Choose a title for your new section:</p>
                        <p className={'text-sm text-columbia_blue-800 mb-4'}>This section will be assigned to {newRosterModal} under your instruction.</p>
                        <textarea className={'pt-2 h-14 text-4xl text-center text-columbia_blue-800 rounded bg-dutch_white-100'} onChange={handleTitleChange}/>
                        {(title) ? <div onClick={handleRosterSubmit} className={'rounded bg-burnt_sienna-600 hover:bg-burnt_sienna-700 text-dutch_white-200 font-bold cursor-pointer mx-auto max-w-[40%] p-2'}>Create New Roster</div> : <div className={'rounded bg-gray-400 text-dutch_white-200 font-bold mx-auto max-w-[40%] p-2 select-none'}>Create New Roster</div>}
                    </div>
                </div>
                {isLoading && <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div> }
            </div>
        </div>
    );
}

export default NewRosterModal;