import React, {useEffect, useState} from 'react';
import putWithAuth from "../../context/putWithAuth";

function StandardBasedGroups({students, standards, groupModal, setGroupModal, school, setSeatingChart}) {

    const [groups, setGroups] = useState(null);
    const [selectedStandard, setSelectedStandard] = useState(null);
    const [showProficiency, setShowProficiency] = useState(false);
    const [groupType, setGroupType] = useState('unlike');
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [groupSize, setGroupSize] = useState(4);

    const handleMinusClick = () => {
        if (groupSize > 2) {
            setGroupSize(prev => prev - 1)
        }
    }

    const handlePlusClick = () => {
        setGroupSize(prev => prev + 1);
    }

    const putSaveSeatingChart = () => {
        let seatingChart = {
            groups: groups,
            rosterId: groupModal.roster._id.$oid
        };
        setIsLoading(true);
        putWithAuth('/logistics/putSaveSeatingChart', school, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: seatingChart
        })
            .then(async response => {
                if (response.ok) {
                    setSeatingChart(seatingChart.groups);
                    setIsLoading(false)
                    setGroupModal(prev => {
                        prev.chartType = 'Seating Chart';
                        return prev;
                    })
                } else {
                    console.error('Failed to save Seating Chart');
                    setIsLoading(false)
                }
            })
            .catch(error => {
                console.error('Error publishing seating chart:', error);
                setIsLoading(false)
            });
    };

    function handleStudentClick(e) {
        if (selectedStudent) {
            let firstStudentCoords = [...selectedStudent]
            let firstStudentInfo = groups[firstStudentCoords[0]][firstStudentCoords[1]]
            let secondStudentCoords = e.target.id.split('-').map(value => parseInt(value, 10))
            let secondStudentInfo = groups[secondStudentCoords[0]][secondStudentCoords[1]]
            let tempGroups = [...groups]
            tempGroups[firstStudentCoords[0]][firstStudentCoords[1]] = secondStudentInfo
            tempGroups[secondStudentCoords[0]][secondStudentCoords[1]] = firstStudentInfo
            setGroups(tempGroups);
            setSelectedStudent(null)
        } else {
            const coord = e.target.id.split('-').map(value => parseInt(value, 10));
            setSelectedStudent(coord)
        }
    }

    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array
    }

    const colors = ['#e48a66', '#538ea5', '#ece0c0', '#1c2f37']
    const textColors = ['#1c2f37', '#ece0c0', '#1c2f37', '#ece0c0']

    function generateGroups(studentEntries, groupTypeParam, standard) {
        let sortedStudents = studentEntries
            .map((item) => {
                let correctCount = 0;
                let totalCount = 0;

                if (standard !== null) {
                    const activeDataEntries = item.active;

                    const resultCount = activeDataEntries.filter(entry => entry.standard === standard && (entry.result === true || entry.result === 0)).length;

                    const recallEntries = activeDataEntries.filter(entry => entry.standard === standard && entry.type === 'Recall');
                    if (recallEntries.length > 0) {
                        const recallItems = recallEntries.flatMap(entry => entry.recallItems);
                        const correctRecallCount = recallItems.map(entry => entry.active).filter(active => active.correct === true).length;
                        const totalRecallItems = recallItems.map(entry => entry.active).length;
                        if (totalRecallItems > 0) {
                            correctCount = resultCount + (correctRecallCount / totalRecallItems);
                        } else {
                            correctCount = resultCount;
                        }
                    } else {
                        correctCount = resultCount;
                    }
                    totalCount = item
                        ? item.active.filter(
                            (item) => item.standard === standard
                        ).length
                        : 0;
                } else {
                    const activeDataEntries = item.active;

                    const resultCount = activeDataEntries.filter(entry => entry.result === true || entry.result === 0).length;

                    const recallEntries = activeDataEntries.filter(entry => entry.type === 'Recall');
                    if (recallEntries.length > 0) {
                        const recallItems = recallEntries.flatMap(entry => entry.recallItems);
                        const correctRecallCount = recallItems.map(entry => entry.active).filter(active => active.correct === true).length;
                        const totalRecallItems = recallItems.map(entry => entry.active).length;

                        if (totalRecallItems > 0) {
                            correctCount = resultCount + (correctRecallCount / totalRecallItems);
                        } else {
                            correctCount = resultCount;
                        }
                    } else {
                        correctCount = resultCount;
                    }
                    totalCount = item ? item.active.length : 0;
                }
                return {
                    firstName: item.firstName,
                    lastName: item.lastName,
                    correctCount: correctCount,
                    totalCount: totalCount
                };
            })
            .sort((a, b) => b.correctCount - a.correctCount);

        const fillGroupWithBlanks = (group) => {
            while (group.length < groupSize) {
                group.push({ firstName: "---", lastName: '', correctCount: 0, totalCount: 1});
            }
        };

        if (groupTypeParam === 'like') {
            let students = [...sortedStudents];
            const groupsConstructor = [];
            while (students.length > groupSize - 1) {
                let group = [];
                while (group.length < groupSize) {
                    group.push(students.pop());
                }
                shuffleArray(group);
                groupsConstructor.push(group);
            }
            if (students.length > 0) {
                let group = [];
                while (students.length > 0) {
                    group.push(students.pop());
                }
                fillGroupWithBlanks(group);
                groupsConstructor.push(group);
            }
            setGroups(groupsConstructor);
        } else if (groupTypeParam === 'unlike') {
            let students = [...sortedStudents];
            const firstHalf = students.slice(0, Math.ceil(students.length / 2));
            const secondHalf = students.slice(Math.ceil(students.length / 2));
            const groupsConstructor = [];
            let alternate = true;

            while (firstHalf.length + secondHalf.length > groupSize - 1) {
                let group = [];
                while (group.length < groupSize && (firstHalf.length > 0 || secondHalf.length > 0)) {
                    if (alternate && firstHalf.length > 0) {
                        group.push(firstHalf.pop());
                    } else if (secondHalf.length > 0) {
                        group.push(secondHalf.pop());
                    }
                    alternate = !alternate;
                }
                groupsConstructor.push(group);
            }

            if (firstHalf.length + secondHalf.length > 0) {
                let group = [];
                while (group.length < groupSize && (firstHalf.length > 0 || secondHalf.length > 0)) {
                    if (firstHalf.length > 0) {
                        group.push(firstHalf.pop());
                    } else if (secondHalf.length > 0) {
                        group.push(secondHalf.pop());
                    }
                }
                fillGroupWithBlanks(group);
                groupsConstructor.push(group);
            }
            setGroups(groupsConstructor);
        }
    }

    const handleStandardChange = async (e) => {
        await setSelectedStandard(e.target.value);
        generateGroups(students, groupType, e.target.value);
    }

    const handleChangeGroupType = () => {
        if (groupType === 'like') {
            setGroupType('unlike');
            generateGroups(students, 'unlike', selectedStandard);
        } else {
            setGroupType('like');
            generateGroups(students, 'like', selectedStandard);
        }

    }

    useEffect(() => {
        generateGroups(students, groupType, selectedStandard)
    }, [groupSize]);

    return (
        <>
            <div className={"bg-burnt_sienna-300 p-2 text-black cursor-black rounded-b rounded-tr overflow-y-auto shadow-xl"}>
                <div className={"bg-dutch_white-300 text-columbia_blue-900 p-2 rounded shadow flex"}>
                    <select className={'bg-columbia_blue-800 p-2 max-w-[25%] text-xs my-auto rounded text-dutch_white-400 font-bold'}
                            onChange={handleStandardChange} defaultValue={""}>
                        <option disabled value={""}>Choose a Standard for Sorting:</option>
                        {standards.map(item => <option value={item.standardCode}
                                                       key={item.standardCode}>{item.code} {item.title}</option>)}
                    </select>
                    <div className={'grow'}/>
                    <div className={'flex'}>
                        <button
                            aria-label={'Decrease Group Size'}
                            className={'h-fit bg-columbia_blue-800 text-dutch_white-400 hover:bg-columbia_blue-700 rounded px-2 my-auto select-none cursor-pointer'}
                            onClick={handleMinusClick}>-
                        </button>
                        <p className={'font-bold text-xl mx-3 select-none my-auto'}>Group Size: {groupSize}</p>
                        <button
                            aria-label={'Increase Group Size'}
                            className={'h-fit bg-columbia_blue-800 text-dutch_white-400 hover:bg-columbia_blue-700 rounded px-2 my-auto select-none cursor-pointer'}
                            onClick={handlePlusClick}>+
                        </button>
                    </div>
                    <div className={'grow'}/>
                    {!showProficiency ? <button className={'bg-columbia_blue-400 text-columbia_blue-900 rounded p-1 font-bold hover:bg-columbia_blue-500'} onClick={() => setShowProficiency(true)}>Show Proficiency</button> : <button className={'bg-columbia_blue-800 rounded p-1 text-dutch_white-300 font-bold hover:bg-columbia_blue-700'} onClick={() => setShowProficiency(false)}>Hide Proficiency</button>}
                    <div className={'grow'} />
                    {groupType === 'unlike' ? <button className={'bg-columbia_blue-400 text-columbia_blue-900 rounded p-1 font-bold hover:bg-columbia_blue-500'} onClick={handleChangeGroupType}>Mixed-level Groups</button> : <button className={'bg-columbia_blue-800 rounded p-1 text-dutch_white-300 font-bold hover:bg-columbia_blue-700'} onClick={handleChangeGroupType}>Same-Level Groups</button>}
                </div>
                <div className={"bg-dutch_white-300 text-columbia_blue-900 p-2 w-fit mx-auto mt-4 rounded shadow flex"}>
                    <div>
                        <p className={'font-bold'}>{groupModal.roster.title}</p>
                        <p className={'text-xs'}>{groupModal.roster.prep}</p>
                    </div>
                </div>

                <div className={"grid grid-cols-4 my-2 gap-2"}>
                    {groups && groups.map((group, groupIndex) =>
                        <div className={"h-full flex flex-col bg-platinum-300 rounded p-1"}>
                            <p className={'font-bold'}>Group {groupIndex + 1}</p>
                            {group.map((student, index) =>
                                (selectedStudent && selectedStudent[0] === groupIndex && selectedStudent[1] === index) ?
                                    <div
                                        onClick={handleStudentClick}
                                        id={`${groupIndex}-${index}`}
                                        className={'p-1 hover:opacity-[85%] animate-pulse rounded mb-1 bg-burnt_sienna text-dutch_white-700 shadow grow flex flex-col cursor-pointer'}
                                        style={{background: colors[index % 4], color: textColors[index % 4]}}>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>
                                        <p id={`${groupIndex}-${index}`}
                                           className={''}>{student.firstName} {student.lastName} {(showProficiency) ? Math.floor(student.correctCount / student.totalCount * 100) + '%' : ''}</p>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>

                                    </div>
                                    :
                                    <div
                                        onClick={handleStudentClick}
                                        id={`${groupIndex}-${index}`}
                                        className={'p-1 hover:opacity-[85%] rounded mb-1 bg-burnt_sienna text-dutch_white-700 shadow grow flex flex-col cursor-pointer'}
                                        style={{background: colors[index % 4], color: textColors[index % 4]}}>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>
                                        <p id={`${groupIndex}-${index}`}
                                           className={''}>{student.firstName} {student.lastName} {(showProficiency) ? Math.floor(student.correctCount / student.totalCount * 100) + '%' : ''}</p>
                                        <div id={`${groupIndex}-${index}`} className={'grow'}/>

                                    </div>)}
                        </div>
                    )}
                </div>
            </div>
            {(!isLoading) ?
                <button onClick={putSaveSeatingChart}
                        className="absolute self-center rounded -bottom-5 left-[50%] -translate-x-1/2 align-middle p-2 bg-columbia_blue-700 text-dutch_white-200 font-bold px-10 hover:bg-columbia_blue-600">
                    <p>Save Seating Chart</p>
                </button> :
                <button className="absolute self-center rounded -bottom-5 left-[50%] -translate-x-1/2 align-middle p-2 bg-columbia_blue-700 text-dutch_white-200 font-bold px-10 hover:bg-columbia_blue-600">
                    <div className="border-t-2 border-b-2 border-burnt_sienna h-6 w-6 rounded-full animate-spin mx-auto"></div>
                </button>
            }
            <div className={'h-32'}></div>
        </>
    );
}

export default StandardBasedGroups;