import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import userContext from "./context/userContext";
import ToSModal from "./components/tosModal";

function Redirect(props) {
    const navigate = useNavigate();
    const user = useContext(userContext)

    useEffect(() => {
        if (user.userContext && user.userContext.termsAccepted) {
            if (user.userContext.role === 'student') {
                navigate('/home');
            } else if (user.userContext.role === 'teacher') {
                navigate('/planning');
            } else if (user.userContext.role === 'coordinator') {
                navigate('/schoolplanning');
            }
        }
    }, [user.userContext]);

    return (
        <div className={'w-fit mx-auto h-screen flex flex-col'}>
            <img className={'mx-auto w-[27%] mt-[10%]'} src={'https://ucarecdn.com/015c824d-7b4e-41e7-885c-a838e592582b/NewLogo.png'} alt={'Brainmeter Logo'}/>
            <p className={'mb-8'}>Redirecting to Brainmeter ...</p>

            <div className={"mx-auto animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-columbia_blue-700"}>
            </div>
            {(user.userContext && !user.userContext.termsAccepted) && <ToSModal user={user}/>}
        </div>
    )
}


export default Redirect;