import React from 'react';

function StandardSelectionBar({standard, standardCode, selectedStandard, setSelectedStandard, knowShow, handleStandardClick}) {

    const order = ['Recall', 'Know', 'Show']

    return (
        <>
            {(selectedStandard && selectedStandard.standardCode === standardCode) ?

                <div tabIndex={0} className={'flex gap-1 flex-col pl-8 p-2 w-[95%] border-2 border-columbia_blue-800 my-2 bg-dutch_white-100 rounded border-opacity-60 mx-auto shadow'}>
                    <div className={'flex mb-1 w-full'}><p className={'pb-1 truncate font-bold text-columbia_blue-800'}>{standard.code}: {standard.title}</p></div>
                    <div className={'flex justify-between gap-[2px] w-full'}>
                        {knowShow.filter(item => (item.type !== "Vocab" && item.active)).sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type)).map(element => (element.type === "Recall") ? <div key={element._id.$oid} className={"bg-columbia_blue-600 rounded grow border-2 border-opacity-30 border-slate-700 pt-[2px]"} /> : (element.type === 'Know')  ? <div key={element._id.$oid} className={"bg-dutch_white-600 rounded grow border-2 border-opacity-30 border-slate-700 pt-[2px]"} /> : <div key={element._id.$oid} className={"bg-paynes_gray-500 rounded grow border-2 border-opacity-30 border-slate-700 pt-[2px]"} />)}
                    </div>
                </div>
                : (standard.classification !== 'base') ?
                    <button className={'flex gap-1 flex-col p-2 w-[90%] border-2 border-columbia_blue-800 my-2 bg-columbia_blue-500 hover:bg-columbia_blue-600 rounded border-opacity-30 mx-auto cursor-pointer shadow'} onClick={() => {handleStandardClick(standard)}}>
                        <div className={'flex mb-1 w-full'}><p
                            className={'pb-1 truncate font-bold text-columbia_blue-800'}>{standard.code}: {standard.title}</p>
                            <div className={'grow'}/>
                            <p className={'text-xs text-columbia_blue-800'}>{standard.classification}</p></div>
                        <div className={'flex justify-between gap-[2px] w-full'}>
                            {knowShow.filter(item => (item.type !== "Vocab" && item.active)).sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type)).map(element => (element.type === "Recall") ? <div key={element._id.$oid} className={"bg-columbia_blue-600 rounded grow border-2 border-opacity-60 border-slate-700 pt-[2px]"} /> : (element.type === 'Know')  ? <div key={element._id.$oid} className={"bg-dutch_white-600 rounded grow border-2 border-opacity-60 border-slate-700 pt-[2px]"} /> : <div key={element._id.$oid} className={"bg-paynes_gray-500 rounded grow border-2 border-opacity-60 border-slate-700 pt-[2px]"} />)}
                        </div>
                    </button>
                :
                    <button className={'flex flex-col p-2 w-[95%] border-2 border-columbia_blue-800 my-2 bg-dutch_white-300 hover:bg-dutch_white-400 rounded border-opacity-30 mx-auto cursor-pointer shadow'} onClick={() => {handleStandardClick(standard)}}>
                        <div className={'flex mb-1 w-full'}><p className={'pb-1 truncate font-bold text-columbia_blue-800'}>{standard.code}: {standard.title}</p></div>
                        <div className={'flex justify-between gap-[2px] w-full'}>
                            {knowShow.filter(item => (item.type !== "Vocab" && item.active)).sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type)).map(element => (element.type === "Recall") ? <div key={element._id.$oid} className={"bg-columbia_blue-600 rounded grow border-2 border-opacity-60 border-slate-700 pt-[1px]"} /> : (element.type === 'Know')  ? <div key={element._id.$oid} className={"bg-dutch_white-600 rounded grow border-2 border-opacity-60 border-slate-700 pt-[1px]"} /> : <div key={element._id.$oid} className={"bg-paynes_gray-500 rounded grow border-2 border-opacity-60 border-slate-700 pt-[2px]"} />)}
                        </div>
                    </button>
            }

        </>
    );
}

export default StandardSelectionBar;