import React, {useState} from 'react';
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import DOMPurify from "dompurify";
import putWithAuth from "../../context/putWithAuth";
import MatchTipTap from "../../components/matchTipTap";

function AddRecallElementsModal({user, addRecallElementsModal, setAddRecallElementsModal, assessmentItems, setAssessmentItems, resetFunction}) {
    // let [passagePreview, setPassagePreview] = useState(null)
    const [matchingSets, setMatchingSets] = useState([{promptType: 'text', prompt: '', matchType: null, match: '', matchUrl: null, matchFile: null}])
    const [isLoading, setIsLoading] = useState(false);
    const [previewUrls, setPreviewUrls] = useState([]);

    const onClose = () => {
        if (window.confirm("Are you sure? Closing this window will remove all question content.")) {
            setAddRecallElementsModal(null)
        } else {
            return null
        }
    }

    const handleClose = (e) => {
        if ( e.target.id === "wrapper" ) onClose();
    }

    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };


    const putAddRecallItems = () => {
        if (!matchingSets || matchingSets.length < 0) {
            window.alert("You must add at least one matched pair to submit.");
            return;
        }

        if (matchingSets.flatMap(item => item.matchType).includes(null) || matchingSets.flatMap(item => item.prompt).includes('')) {
            window.alert("Please clear any incomplete match pairs before submitting.")
            return;
        }

        setIsLoading(true)

        // Array to store updated matched pairs with image URLs
        const updatedMatchedPairs = [];

        // Iterate over each matched pair
        Promise.all(
            matchingSets.map(async (pair, index) => {
                // Check prompt type
                if (pair.promptType === "image") {
                    // Upload prompt image
                    const promptUrl = await uploadImage(pair.promptFile);
                    pair.prompt = `https://ucarecdn.com/${promptUrl}/`;
                }

                // Check match type
                if (pair.matchType === "image") {
                    // Upload match image
                    const matchUrl = await uploadImage(pair.matchFile);
                    pair.match =  `https://ucarecdn.com/${matchUrl}/`;
                }

                // Add updated pair to the array
                updatedMatchedPairs.push(pair);
            })
        )
            .then(() => {
                // After all images are uploaded and URLs are updated
                // Send the updated matched pairs to your API
                putWithAuth('/planning/putAddRecallItems', user.userAttributes['custom:school'], {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: {
                        knowShowId: addRecallElementsModal.knowShowId,
                        title: addRecallElementsModal.title,
                        standard: addRecallElementsModal.standard,
                        standardTitle: addRecallElementsModal.standardTitle,
                        prompt: addRecallElementsModal.prompt,
                        matchSets: updatedMatchedPairs.map(item => ({prompt: {type: item.promptType, content: item.prompt}, match: {type: item.matchType, content: item.match}})),
                        school: user.userAttributes['custom:school'],
                        prep: addRecallElementsModal.prep
                    }
                })
                    .then(async response => {
                        if (response.ok) {
                            // Handle success
                            await resetFunction(user);
                            window.alert('Recall Elements Successfully Created')
                            setIsLoading(false);
                            setAddRecallElementsModal(null);
                        } else {
                            setIsLoading(false)
                            throw new Error('Failed to publish recall pairs.');
                        }
                    })
                    .catch(error => {
                        setIsLoading(false)
                        console.error(error);
                        // Handle error
                    });
            });
    };

// Function to upload image to Uploadcare API and return URL
    const uploadImage = async (file) => {
        try {
            const formData = new FormData();
            formData.set("UPLOADCARE_PUB_KEY", process.env.REACT_APP_UPLOADCARE_PUB_KEY);
            formData.set("UPLOADCARE_STORE", 1);
            formData.append('file', file)
            const response = await fetch('https://upload.uploadcare.com/base/', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to upload image.');
            }
            const data = await response.json();
            return data.file; // Assuming the response contains the URL of the uploaded image
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const handlePaste = (e) => {
        // Prevent the default paste behavior
        e.preventDefault();

        // Get the pasted text
        const text = e.clipboardData.getData('text/plain');

        // Insert the text into the editor
        const quill = quillRef.current.getEditor();
        quill.clipboard.dangerouslyPasteHTML(text);
    };

    const quillRef = React.createRef()

    const handleAddPair = () => {
        setMatchingSets([...matchingSets, {promptType: 'text', prompt: '', matchType: null, match: '', matchUrl: null, matchFile: null}])
    }

    const handleSelectMatchType = (item, index, matchType) => {
        let tempMatchingSets = [...matchingSets]
        tempMatchingSets[index].matchType = matchType
        setMatchingSets(tempMatchingSets)
    }

    const handleEditorPromptChange = (content, index) => {
        matchingSets[index].prompt = content;
    };

    const handleEditorMatchChange = (content, index) => {
        matchingSets[index].match = content;
    };


    const handleAddImage = (index, category) => {
        // Create a new file input element
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg, image/png, image/jpg';
        fileInput.style.display = 'none';
        fileInput.onchange = (event) => handleFileInputChange(event, index, category);

        // Trigger click on the new file input
        fileInput.click();
    };

    const handleFileInputChange = (event, index, category) => {
        const file = event.target.files[0];
        if (file.size > 5000000) {
            alert('Your file is too large. 5mb maximum image size.')
            return null;
        }
        const reader = new FileReader();
        reader.onload = () => {
            const previewUrl = URL.createObjectURL(file);
            let tempMatches = [...matchingSets]
            tempMatches[index][category + 'Type'] = 'image'
            tempMatches[index][category + 'File'] = file
            tempMatches[index][category + 'Url'] = previewUrl
            setMatchingSets(tempMatches)
        };
        reader.readAsDataURL(file);
    };

    const handleRemovePair = (index) => {
        setMatchingSets(matchingSets.filter((item, position) => position !== index))
    }

    return (
        <div className={"fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm justify-center items-center flex flex-col z-10"} id="wrapper" onClick={handleClose}>
            <div className={"relative bg-platinum-500 m-4 rounded p-2 w-[80%] max-h-[95%]"}>
                <button onClick={onClose} className="absolute -top-6 -right-6 m-2 p-2 w-10 rounded-full bg-burnt_sienna text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path fill="#fff" d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                    </svg>
                </button>
                <div className={'p-2 bg-columbia_blue-800 flex flex-col max-h-full'}>
                    <div className={"bg-dutch_white-500 p-4 text-black cursor-black rounded-b rounded-tr max-h-full overflow-auto border-8 border-dutch_white-500"}>
                        <div className={"bg-dutch_white-300 text-2xl p-4 rounded shadow"}>{addRecallElementsModal.title}</div>
                        <div className={'flex mx-auto w-fit gap-2 bg-paynes_gray-200 rounded-b px-4 shadow-inner py-1'}>
                            <p className={'font-bold'}>Prompt:</p>
                            <div dangerouslySetInnerHTML={renderHtml(addRecallElementsModal.prompt)} />
                    </div>
                        {(addRecallElementsModal.recallItems.length > 0) &&
                            <div className={'bg-columbia_blue-800 rounded mt-1'}>
                                <p className={'text-dutch_white-300 font-bold text-lg'}>Current Items:</p>
                                <div className={'flex flex-col gap-2 px-4 pb-2'}>
                                {addRecallElementsModal.recallItems.map(item =>
                                    <div className={'flex'}>
                                        <div
                                            className={'border-dutch_white-800 border-2 shadow bg-columbia_blue-400 text-dutch_white-800 p-1 rounded font-bold h-fit my-auto'}>
                                            {(item.prompt.type === 'image') ? <img src={item.prompt.content}
                                                                                   className={"rounded max-h-[100px] mx-auto mb-2 shadow"}/> :
                                                <div className={'p-2'} dangerouslySetInnerHTML={renderHtml(item.prompt.content)}/>}
                                        </div>
                                        <div
                                            className={'grow border-b-4 border-dotted border-columbia_blue-400 h-1/2 min-w-[20px] my-auto'}/>
                                        <div
                                            className={'border-dutch_white-800 border-2 shadow bg-dutch_white-400 text-dutch_white-900 p-1 rounded h-fit my-auto'}>
                                            {(item.match.type === 'image') ? <img src={item.match.content}
                                                                                  className={"rounded max-h-[100px] mx-auto mb-2 shadow"}/> :
                                                <div className={'p-2'} dangerouslySetInnerHTML={renderHtml(item.match.content)}/>}
                                        </div>
                                    </div>)}
                                </div>
                                </div>
                        }
                                <div className={"my-2"}>
                                    <div className={"h-full flex flex-col mx-2 gap-4 overflow-auto"}>
                                        {(matchingSets.map((item, index) =>
                                            <div className={'flex w-full bg-dutch_white-100 p-2 rounded bg-opacity-50 shadow'}>
                                                <div onClick={() => handleRemovePair(index)}
                                                     className={'bg-burnt_sienna hover:bg-burnt_sienna-600 text-dutch_white-300 rounded p-2 h-fit my-auto select-none cursor-pointer mr-2'}>X
                                                </div>
                                                {(item.promptType) ? (item.promptType === 'text') ?
                                                        <div className={'w-2/5 my-auto p-2 bg-paynes_gray-300 rounded shadow'}>
                                                            <MatchTipTap setHtmlOutput={handleEditorPromptChange} index={index} content={matchingSets[index].prompt} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Prompt...'} />
                                                            {/*<ReactQuill ref={quillRef} onPaste={handlePaste}*/}
                                                            {/*            modules={{toolbar: [['bold', 'italic', 'underline'], [{script: 'sub'}, {script: 'super'}]]}}*/}
                                                            {/*            id={"prompt"}*/}
                                                            {/*            onChange={(content, delta, source, editor) => handleEditorPromptChange(content, delta, source, editor, index)}*/}
                                                            {/*            placeholder={"Prompt:"}*/}
                                                            {/*            className={'bg-burnt_sienna-100'}/>*/}
                                                        </div> : (item.promptType === 'image') ? (item.promptUrl) ? <div className={'flex flex-col bg-burnt_sienna-600 w-2/5 py-3 rounded'}>
                                                    <div className={'flex h-full'}>
                                                        <img src={item.promptUrl} className={'h-32 mx-auto rounded'}/>
                                                    </div>
                                                </div> : <div className={'flex flex-col bg-burnt_sienna-600 w-2/5 py-3 rounded'}>
                                                    <div className={'flex h-full'}>
                                                        <div onClick={() => handleAddImage(index, 'prompt')} className={'w-2/5 bg-dutch_white-300 my-auto rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900 mx-auto'}>Select Prompt Image</div>
                                                    </div>
                                                </div> : <></>
                                            : <></>}
                                        <div className={'grow h-1/2 my-auto border-dotted border-b-8 border-columbia_blue-700'}/>
                                        {(item.matchType) ? (item.matchType === 'text') ?
                                                <div className={'w-2/5 mr-1 p-2 bg-paynes_gray-300 rounded shadow'}>
                                                    <MatchTipTap setHtmlOutput={handleEditorMatchChange} index={index} content={matchingSets[index].match} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Match...'} />

                                                    {/*<ReactQuill ref={quillRef} onPaste={handlePaste} modules={{ toolbar: [['bold', 'italic', 'underline'], [{ script: 'sub' }, { script: 'super' }]] }} id={"prompt"} onChange={(content, delta, source, editor) => handleEditorMatchChange(content, delta, source, editor, index)} placeholder={"Match:"} className={'bg-columbia_blue-100'}/>*/}
                                                </div> : (item.matchType === 'image') ? (item.matchUrl) ? <div className={'flex flex-col bg-columbia_blue-600 w-2/5 py-3 rounded mr-1'}>
                                                    <div className={'flex h-full'}>
                                                        <img src={item.matchUrl} className={'h-32 mx-auto rounded'}/>
                                                    </div>
                                                </div> : <div className={'flex flex-col bg-columbia_blue-600 w-2/5 py-3 rounded mr-1'}>
                                                    <div className={'flex h-full'}>
                                                        <div onClick={() => handleAddImage(index, 'match')} className={'w-2/5 bg-dutch_white-300 my-auto rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900 mx-auto'}>Select Match Image</div>
                                                    </div>
                                                </div>  : <></>
                                            : <div className={'flex flex-col bg-columbia_blue-800 w-2/5 py-3 rounded mr-1'}>
                                                <p className={'font-bold text-dutch_white-200'}>Select Match Type</p>
                                                <div className={'flex'}>
                                                    <div onClick={() => handleSelectMatchType(item, index, 'text')} className={'w-2/5 bg-dutch_white-300 ml-4 mt-2 rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900'}>Text</div>
                                                    <div className={'grow'} />
                                                    <div onClick={() => handleAddImage(index, 'match')} className={'w-2/5 bg-dutch_white-300 mr-4 mt-2 rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900'}>Image</div>
                                                </div>
                                            </div>}
                                    </div>))}
                                {/*<button className="absolute top-[63%] right-[58%] rounded-full bg-blue-500 text-white w-[6%] h-[12%] flex items-center justify-center">*/}
                                {/*</button>*/}
                                {/*<button className="absolute top-[63%] right-[58%] rounded-full bg-blue-500 text-white w-[6%] h-[12%] flex items-center justify-center animate-pulse hover:animate-none">*/}
                                {/*    <span className="text-xl">AI</span>*/}
                                {/*</button>*/}
                                <div onClick={handleAddPair} className={'bg-platinum-900 text-dutch_white-200 w-fit p-2 mt-4 mx-auto rounded font-bold cursor-pointer select-none hover:bg-platinum-700'}>Add Recall Pair</div>
                                {previewUrls.map(item => <img src={item}/>)}
                            </div>
                        </div>
                    </div>
                    <button onClick={putAddRecallItems} className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-columbia_blue-700 text-white font-bold px-10 text-xl hover:bg-columbia_blue-600">
                        <p>Save New Recall Items</p>
                    </button>

                </div>
            </div>
            {/*<button onClick={publishTraditionalMCItem} className="relative self-center rounded bottom-12 align-middle m-2 p-2 bg-columbia_blue-700 text-white font-bold px-10 text-xl hover:bg-columbia_blue-600">*/}
            {/*    <p>Publish Item</p>*/}
            {/*</button>*/}
            {(isLoading) && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                    {/* Loader */}
                    <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-100"></div>
                </div>
            )}
        </div>
    )
}

export default AddRecallElementsModal;