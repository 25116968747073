import React, {useState} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import postWithAuth from "../../context/postWithAuth";
import TipTap from "../../components/tipTap";
import SortingTipTap from "../../components/sortingTipTap";
import MatchTipTap from "../../components/matchTipTap";

function SortingInput({user, createItemModal, setCreateItemModal, setIsLoading, assessmentItems, setAssessmentItems}) {
    const [categories, setCategories] = useState([{title: null, items: [{type: null, content: null}]}])
    const [prompt, setPrompt] = useState(null);
    const [promptImage, setPromptImage] = useState(null);
    const [previewUrls, setPreviewUrls] = useState([]);

    console.log(categories)



    const handlePublishSortingItem = () => {
        if (!categories || categories.length < 2) {
            window.alert("You must add at least two categories to submit.");
            return;
        }

        if (categories.flatMap(item => item.items).includes([]) || categories.flatMap(item => item.content).includes('')) {
            window.alert("Please clear any incomplete categories before submitting.")
            return;
        }

        if (!prompt) {
            window.alert("Your assessment item must start with an overall prompt.");
            return;
        }

        if (window.confirm('Are you sure you have included everything? The assessment item cannot be edited once it has been submitted.')) {
            setIsLoading(true)

            const uploadImagesForCategory = async (category) => {
                const updatedItems = await Promise.all(
                    category.items.map(async (entry) => {
                        if (entry.type === "image") {
                            // Upload prompt image
                            const promptUrl = await uploadImage(entry.file);
                            entry.content = `https://ucarecdn.com/${promptUrl}/`;
                        }
                        return {type: entry.type, content: entry.content}; // Return the entry after processing
                    })
                );

                // Return the updated category with updated items
                return { ...category, items: updatedItems };
            };

            // Iterate over each category and process the images
            Promise.all(
                categories.map(uploadImagesForCategory)
            )
                .then((updatedCategories) => {
                    // After all images are uploaded and URLs are updated
                    // Send the updated matched pairs to your API

                    postWithAuth('/planning/postSortingItem', user.userAttributes['custom:school'], {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: {
                            knowShowId: createItemModal._id.$oid,
                            knowShowText: createItemModal.text,
                            standard: createItemModal.standard,
                            prompt: prompt,
                            categories: updatedCategories,
                            school: user.userAttributes['custom:school'],
                            prep: createItemModal.prep
                        }
                    })
                        .then(async (response) => {
                            if (!response.ok) {
                                throw new Error('Failed to make the second request.');
                            } else {
                                return response.json();
                            }
                        })
                        .then(async data => {
                            await setAssessmentItems(prev => [...prev, data]);
                            setIsLoading(false);
                            setCreateItemModal(null);
                        })
                        .catch(error => {
                            setIsLoading(false)
                            console.error(error);
                            // Handle error
                        })
                })
        } else {
            return null
        }
    }

// Function to upload image to Uploadcare API and return URL
    const uploadImage = async (file) => {
        try {
            const formData = new FormData();
            formData.set("UPLOADCARE_PUB_KEY", process.env.REACT_APP_UPLOADCARE_PUB_KEY);
            formData.set("UPLOADCARE_STORE", 1);
            formData.append('file', file)
            const response = await fetch('https://upload.uploadcare.com/base/', {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error('Failed to upload image.');
            }
            const data = await response.json();
            return data.file; // Assuming the response contains the URL of the uploaded image
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const handlePaste = (e) => {
        // Prevent the default paste behavior
        e.preventDefault();

        // Get the pasted text
        const text = e.clipboardData.getData('text/plain');

        // Insert the text into the editor
        const quill = quillRef.current.getEditor();
        quill.clipboard.dangerouslyPasteHTML(text);
    };

    const quillRef = React.createRef()

    const handleAddCategory = () => {
        setCategories([...categories, {title: null, items: [{type: null, content: null}]}])
    }

    const handleAddItem = (index) => {
        let tempCategories = [...categories]
        tempCategories[index].items.push({type: null, content: null})
        setCategories(tempCategories)
    }

    const handleRemoveItem = (index, entryIndex) => {
        let tempCategories = [...categories]
        let tempBucket = tempCategories[index]
        tempBucket.items = tempBucket.items.filter((item, position) => position !== entryIndex)
        tempCategories[index] = tempBucket
        setCategories(tempCategories)
    }

    const handleSelectItemType = (item, index, entryIndex, itemType) => {
        let tempCategories = [...categories]
        tempCategories[index].items[entryIndex].type = itemType
        tempCategories[index].items[entryIndex].content = null
        setCategories(tempCategories)
    }

    const handleCategoryTitleChange = (content, index) => {
        categories[index].title = content;
    };

    const handleTextItemChange = (content, index, entryIndex) => {
        categories[index].items[entryIndex].content = content;
    };

    const handleOverallPromptChange = (content) => {
        setPrompt(content);
    }

    const handleAddImage = (index, entryIndex) => {
        // Create a new file input element
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg, image/png, image/jpg';
        fileInput.style.display = 'none';
        fileInput.onchange = (event) => handleFileInputChange(event, index, entryIndex);

        // Trigger click on the new file input
        fileInput.click();
    };

    const handleFileInputChange = (event, index, entryIndex) => {
        const file = event.target.files[0];
        if (file.size > 5000000) {
            alert('Your file is too large. 5mb maximum image size.')
            return null;
        }
        const reader = new FileReader();
        reader.onload = () => {
            const previewUrl = URL.createObjectURL(file);
            let tempMatches = [...categories]
            tempMatches[index].items[entryIndex]['type'] = 'image'
            tempMatches[index].items[entryIndex]['file'] = file
            tempMatches[index].items[entryIndex]['url'] = previewUrl
            setCategories(tempMatches)
        };
        reader.readAsDataURL(file);
    };

    const handleRemoveCategory = (index) => {
        setCategories(categories.filter((item, position) => position !== index))
    }

    return (
        <>
            <div className={"bg-paynes_gray-300 p-4 text-black cursor-black rounded-b rounded-tr max-h-full overflow-auto border-8 border-paynes_gray-300"}>
                <div className={"bg-dutch_white-300 text-lg p-4 rounded shadow"}>{createItemModal.text}</div>
                <div>
                    <div className={'w-3/5 mx-auto mt-1'}>
                        <TipTap setHtmlOutput={setPrompt} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Overall Sorting Prompt...'} />

                        {/*<ReactQuill ref={quillRef} onPaste={handlePaste} modules={{toolbar: [['bold', 'italic', 'underline'], [{ script: 'sub' }, { script: 'super' }]] }} id={"overallPrompt"} onChange={(content, delta, source, editor) => handleOverallPromptChange(content, delta, source, editor)} placeholder={"Overall Sorting Prompt:"} className={'bg-dutch_white-200 shadow'}/>*/}
                    </div>
                </div>
                <div className={"my-2"}>
                    <div className={"h-full grid grid-cols-2 gap-4 mx-2 overflow-y-auto overflow-x-hidden p-2"}>
                        {(categories.map((item, index) =>
                            <div className={'flex flex-col w-full bg-paynes_gray-800 rounded p-1'}>

                                <div className={'flex justify-between rounded'}>

                                    <div className={'w-full px-1 mx-auto mt-1'}>
                                        <MatchTipTap setHtmlOutput={handleCategoryTitleChange} index={index} content={categories[index].title} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-dutch_white-200'} placeholder={'Category Title...'} />

                                        {/*<ReactQuill ref={quillRef} onPaste={handlePaste}*/}
                                        {/*            modules={{toolbar: [['bold', 'italic', 'underline'], [{script: 'sub'}, {script: 'super'}]]}}*/}
                                        {/*            id={`categoryTitle${index}`}*/}
                                        {/*            value={categories[index].title}*/}
                                        {/*            onChange={(content, delta, source, editor) => handleCategoryTitleChange(content, delta, source, editor, index)}*/}
                                        {/*            placeholder={"Category Title"}*/}
                                        {/*            className={'bg-dutch_white-200 shadow'}/>*/}
                                    </div>
                                    <div className={'w-0'}>
                                        <button onClick={() => handleRemoveCategory(index)}
                                                className="relative -top-2 right-4 w-6 h-6 rounded-full bg-burnt_sienna hover:bg-burnt_sienna-600 text-white">
                                            <svg className={'mx-auto'} xmlns="http://www.w3.org/2000/svg" width="12"
                                                 height="12"
                                                 viewBox="0 0 24 24">
                                                <path fill="#fff"
                                                      d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                            </svg>
                                        </button>
                                    </div>

                                    {/*<div onClick={() => handleRemoveCategory(index)}*/}
                                    {/*     className={'max-w-min bg-burnt_sienna hover:bg-burnt_sienna-600 text-dutch_white-300 rounded p-1 h-fit text-xs select-none cursor-pointer m-1 pb-2'}>*/}
                                    {/*    Remove*/}
                                    {/*    Category*/}
                                    {/*</div>*/}

                                </div>
                                <div className={'flex flex-col gap-2 border-t-2 border-dutch_white-300 pt-4 mt-4'}>
                                    {item.items.map((entry, entryIndex) =>
                                        (entry.type === 'image') ? (entry.url) ?
                                            <div className={'flex h-full'}>
                                            <button onClick={() => handleRemoveItem(index, entryIndex)}
                                                     className={'bg-burnt_sienna hover:bg-burnt_sienna-600 text-dutch_white-300 rounded p-2 h-fit select-none cursor-pointer mx-2 my-auto'}>X
                                                </button>
                                                <img src={entry.url} className={'h-32 mx-auto rounded'}/>
                                            </div>
                                            : <button onClick={() => handleAddImage(index, entryIndex)}
                                                   className={'w-2/5 bg-dutch_white-300 my-auto rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900 mx-auto'}>
                                                Add Image
                                            </button> : (entry.type === 'text') ?
                                            <div className={'flex justify-between rounded'}>
                                                <button onClick={() => handleRemoveItem(index, entryIndex)}
                                                     className={'bg-burnt_sienna hover:bg-burnt_sienna-600 text-dutch_white-300 rounded p-2 h-fit select-none cursor-pointer mx-2 my-auto'}>X
                                                </button>
                                                <div className={'w-4/5 mx-auto mt-1'}>
                                                    <SortingTipTap setHtmlOutput={handleTextItemChange} index={index} content={categories[index].items[entryIndex].content} entryIndex={entryIndex} textColor={'text-columbia_blue-900'} fontSize={'text-lg'} backgroundColor={'bg-columbia_blue-300'} placeholder={'Entry...'} />

                                                    {/*<ReactQuill value={categories[index].items[entryIndex].content}*/}
                                                    {/*            ref={quillRef} onPaste={handlePaste}*/}
                                                    {/*            modules={{toolbar: [['bold', 'italic', 'underline'], [{script: 'sub'}, {script: 'super'}]]}}*/}
                                                    {/*            id={"overallPrompt"}*/}
                                                    {/*            onChange={(content, delta, source, editor) => handleTextItemChange(content, delta, source, editor, index, entryIndex)}*/}
                                                    {/*            placeholder={"Category Title"}*/}
                                                    {/*            className={'bg-dutch_white-200 shadow'}/>*/}
                                                </div>
                                            </div>
                                            :
                                            <div className={'flex w-full'}>
                                                <button onClick={() => handleRemoveItem(index, entryIndex)}
                                                     className={'bg-burnt_sienna hover:bg-burnt_sienna-600 text-dutch_white-300 rounded p-2 h-fit select-none cursor-pointer mx-2 my-auto'}>
                                                    <svg className={'mx-auto'} xmlns="http://www.w3.org/2000/svg"
                                                         width="12"
                                                         height="12"
                                                         viewBox="0 0 24 24">
                                                        <path fill="#fff"
                                                              d="M14.12 12l5.3-5.3c.59-.59.59-1.54 0-2.12-.59-.59-1.54-.59-2.12 0L12 9.88 6.7 4.58c-.59-.59-1.54-.59-2.12 0-.59.59-.59 1.54 0 2.12L9.88 12l-5.3 5.3c-.59.59-.59 1.54 0 2.12.59.59 1.54.59 2.12 0L12 14.12l5.3 5.3c.59.59 1.54.59 2.12 0 .59-.59.59-1.54 0-2.12L14.12 12z"/>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={'flex flex-col bg-columbia_blue-800 my-1 py-3 rounded w-full'}>
                                                    <p className={'font-bold text-dutch_white-200'}>Select Item
                                                        Type</p>
                                                    <div className={'flex grow'}>
                                                        <button
                                                            onClick={() => handleSelectItemType(item, index, entryIndex, 'text')}
                                                            className={'w-2/5 bg-dutch_white-300 ml-4 mt-2 rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900'}>Text
                                                        </button>
                                                        <div className={'grow'}/>
                                                        <button onClick={() => handleAddImage(index, entryIndex)}
                                                             className={'w-2/5 bg-dutch_white-300 mr-4 mt-2 rounded font-bold select-none hover:bg-dutch_white-500 cursor-pointer py-2 text-dutch_white-900'}>Image
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                    )}
                                    <button onClick={() => handleAddItem(index)}
                                         className={'bg-paynes_gray-600 text-dutch_white-200 w-fit p-2 mt-4 mb-2 mx-auto rounded font-bold cursor-pointer select-none hover:bg-platinum-700'}>Add Item</button>
                                    {previewUrls.map(item => <img src={item}/>)}

                                </div>



                                {/*<button className="absolute top-[63%] right-[58%] rounded-full bg-blue-500 text-white w-[6%] h-[12%] flex items-center justify-center">*/}
                                {/*</button>*/}
                                {/*<button className="absolute top-[63%] right-[58%] rounded-full bg-blue-500 text-white w-[6%] h-[12%] flex items-center justify-center animate-pulse hover:animate-none">*/}
                                {/*    <span className="text-xl">AI</span>*/}
                                {/*</button>*/}


                            </div>))}
                        {(categories.length < 4) ? <button onClick={handleAddCategory}
                                                        className={'bg-platinum-900 h-fit text-dutch_white-200 w-fit p-2 mt-4 mx-auto rounded font-bold cursor-pointer select-none hover:bg-platinum-700'}>Add
                            Category</button> : <div className={'bg-platinum-600 h-fit text-dutch_white-200 w-fit p-2 mt-4 mx-auto rounded font-bold select-none hover:bg-platinum-700'}>Four is the Maximum Number of Categories</div>}
                        {previewUrls.map(item => <img src={item}/>
                    )}
                </div>
            </div>
        </div>

    <button onClick={handlePublishSortingItem} className="absolute self-center rounded -bottom-6 align-middle m-2 p-2 bg-columbia_blue-700 text-white font-bold px-10 text-xl hover:bg-columbia_blue-600">
                <p>Publish Assessment Item</p>
            </button>
        </>
    );
}

export default SortingInput;