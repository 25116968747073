import React, {useState} from 'react';
import DOMPurify from "dompurify";
import SortingHoverStudentTooltip from "./sortingHoverStudentTooltip";
import SortingHoverItemTooltip from "./sortingHoverItemTooltip";

function SortingAnswerDistribution({ question, responses, studentData }) {
    const [hoveredStudent, setHoveredStudent] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);
    const [mousePosition, setMousePosition] = useState({ x: -15, y: 0 });
    const renderHtml = (htmlString) => {
        const sanitizedHtml = DOMPurify.sanitize(htmlString);
        return { __html: sanitizedHtml };
    };

    const handleMouseMove = (event) => {
        setMousePosition({ x: event.clientX - 250, y: event.clientY - 50});
    };

    const liveResponses = responses.filter(item => item.result !== 'pending');


    return (
        <div className={'grid grid-cols-4 gap-2 px-2'}>
            <div className={'rounded bg-columbia_blue-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Perfect Sort</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {responses.filter(questionResponse => questionResponse.result === 0)
                        .map(item => (
                            <div className={'p-1 bg-dutch_white-400 text-burnt_sienna-900 rounded'}>
                                <p>{studentData.find(entry => item.studentId === entry.studentId).firstName} {studentData.find(entry => item.studentId === entry.studentId).lastName} </p>
                            </div>))}
                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Misclassifications</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {[...new Set(liveResponses.filter(questionResponse => questionResponse.result > 0)
                        .map(item => item.result)
                    )
                    ].sort((a, b) => b - a) // Sort the unique values in ascending order
                        .map(result => (
                            <div key={result} className={'p-1 bg-dutch_white-400 text-sm text-burnt_sienna-900 rounded mb-2'}>
                                <p className={'font-bold underline mb-2'}>{result} misclassified</p>
                                {liveResponses.filter(questionResponse => questionResponse.result === result).map(student =>
                                    <div className={'w-[80%] rounded mx-auto bg-paynes_gray-200 p-1'}
                                         onMouseEnter={() => setHoveredStudent({response: student, name: `${studentData.find(entry => student.studentId === entry.studentId).firstName} ${studentData.find(entry => student.studentId === entry.studentId).lastName}`})}
                                         onMouseLeave={() => setHoveredStudent(null)}
                                         onMouseMove={handleMouseMove}>
                                        <p className={'select-none'}>{studentData.find(entry => student.studentId === entry.studentId).firstName} {studentData.find(entry => student.studentId === entry.studentId).lastName}</p>
                                        {(hoveredStudent) &&
                                            <div
                                                className="absolute bg-white p-2 shadow rounded opacity-95 z-10"
                                                style={{left: mousePosition.x - 110, top: mousePosition.y - 10, // Adjust the y-coordinate to avoid overlapping the mouse
                                                    transform: 'translateY(-100%)'}}>
                                                <SortingHoverStudentTooltip data={hoveredStudent}/>
                                            </div>}
                                    </div>)}
                            </div>
                        ))}

                </div>
            </div>
            <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                <p className={'font-bold'}>Item Analysis:</p>
                <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                    {[...new Map(
                        responses.filter(item => item.result !== 'pending')
                            .flatMap(item => item.sortingOutcome)
                            .flatMap(item => item.incorrect)
                            .map(obj => [obj.type + obj.content, obj])
                    ).values()].sort((a,b) => {
                        const countA = liveResponses.flatMap(item => item.sortingOutcome).flatMap(item => item.incorrect).filter(entry => entry.content === a.content).length;
                        const countB = liveResponses.flatMap(item => item.sortingOutcome).flatMap(item => item.incorrect).filter(entry => entry.content === b.content).length;

                        if (countA !== countB) {
                            return countB - countA; // Sort by count in descending order
                        } else {
                            return a.content.localeCompare(b.content); // If counts are equal, sort alphabetically by content
                        }}).map(item => (
                        <div className={'bg-dutch_white-300 text-columbia_blue-900 flex p-2 rounded border-dutch_white-700 border-2'}>
                            {
                                (item.type === 'text') ?
                                    <div className={'my-auto'} onMouseMove={handleMouseMove} onMouseEnter={() => setHoveredItem({item: item, names: studentData.filter(student => liveResponses.map(item => item.studentId).includes(student.studentId) && liveResponses.find(entry => entry.studentId === student.studentId && entry.sortingOutcome.flatMap(item => item.incorrect).map(item => item.content).includes(item.content))).map(student => `${student.firstName} ${student.lastName}`)})} onMouseLeave={() => setHoveredItem(null)} dangerouslySetInnerHTML={renderHtml(item.content)}/> : <img className={'h-[50px]'} onMouseEnter={() => setHoveredItem(item)} onMouseLeave={() => setHoveredItem(null)} src={item.content} />
                            }
                            {(hoveredItem) &&
                                <div
                                    className="absolute bg-white p-2 shadow rounded opacity-95 z-10"
                                    style={{left: mousePosition.x, top: mousePosition.y - 10, // Adjust the y-coordinate to avoid overlapping the mouse
                                        transform: 'translateY(-100%)'}}>
                                    <SortingHoverItemTooltip data={hoveredItem}/>
                                </div>}
                            <div className={'ml-auto my-auto py-1 px-2 rounded bg-burnt_sienna text-columbia_blue-900 text-lg font-bold'}>
                                {liveResponses.flatMap(item => item.sortingOutcome).flatMap(item => item.incorrect).filter(entry => entry.content === item.content).length}
                            </div>
                        </div>
                    ))}
                    {/*{question.categories.sort((a, b) => a.content.toLowerCase().localeCompare(b.content.toLowerCase())).map(item =>*/}
                    {/*    <div className={'bg-dutch_white-300 text-columbia_blue-900 flex p-2 rounded border-dutch_white-700 border-2'}>*/}
                    {/*        <div className={'my-auto'} dangerouslySetInnerHTML={renderHtml(item.prompt.content)} /><div className={'ml-auto my-auto py-1 px-2 rounded bg-burnt_sienna text-columbia_blue-900 text-lg font-bold'}>{responses.filter(questionResponse => questionResponse.response !== 'pending')*/}
                    {/*        .flatMap(studentData => studentData.response)*/}
                    {/*        .filter(entry => entry.prompt.content === item.prompt.content && entry.response.content !== item.match.content)*/}
                    {/*        .length}</div></div>)}*/}
                </div>
            </div>
                <div className={'rounded bg-burnt_sienna-700 text-dutch_white-400 shadow p-2'}>
                    <p className={'font-bold'}>Category Analysis:</p>
                    <div className={'max-h-[400px] flex flex-col gap-1 overflow-y-auto px-2'}>
                        {question.categories.map(item => item.title).map(item => (
                            <div className={'bg-dutch_white-300 text-columbia_blue-900 flex p-2 rounded border-dutch_white-700 border-2 justify-between'}>
                                <div className={'my-auto'} dangerouslySetInnerHTML={renderHtml(item)}/>
                                <div className={'flex gap-2'}>
                                    <div
                                        className={'my-auto py-1 px-2 rounded bg-burnt_sienna text-columbia_blue-900 text-lg font-bold'}>
                                        {liveResponses.flatMap(item => item.sortingOutcome).filter(entry => entry.title === item).flatMap(item => item.incorrect).length}
                                    </div>
                                    <div
                                        className={'my-auto py-1 px-2 rounded bg-paynes_gray-300 text-columbia_blue-900 text-lg font-bold'}>
                                        {liveResponses.flatMap(item => item.sortingOutcome).filter(entry => entry.title === item).flatMap(item => item.missing).length}
                                    </div>
                                </div>

                            </div>

                        ))}
                    </div>
                </div>
            {/*{question.categories.sort((a, b) => a.content.toLowerCase().localeCompare(b.content.toLowerCase())).map(item =>*/}
            {/*    <div className={'bg-dutch_white-300 text-columbia_blue-900 flex p-2 rounded border-dutch_white-700 border-2'}>*/}
            {/*        <div className={'my-auto'} dangerouslySetInnerHTML={renderHtml(item.prompt.content)} /><div className={'ml-auto my-auto py-1 px-2 rounded bg-burnt_sienna text-columbia_blue-900 text-lg font-bold'}>{responses.filter(questionResponse => questionResponse.response !== 'pending')*/}
            {/*        .flatMap(studentData => studentData.response)*/}
                        {/*        .filter(entry => entry.prompt.content === item.prompt.content && entry.response.content !== item.match.content)*/}
                        {/*        .length}</div></div>)}*/}

        </div>
    );
}
export default SortingAnswerDistribution;