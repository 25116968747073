import React from 'react';
import OverallStandardSpeedometer from "../../teacher/data/overallStandardSpeedometer";

function StandardSummaryBlock({setStandardElementsModal, standard, standardData}) {
    return (
        <button className={'rounded w-full flex flex-col bg-dutch_white-400 border-[3px] border-dutch_white-800 shadow-lg text-columbia_blue-800 py-2 px-4 hover:bg-dutch_white-500 cursor-pointer overflow-x-hidden'}
             onClick={() => setStandardElementsModal(
                 {standard: standard, standardData: standardData}
             )}>
            <p className={'text-columbia_blue-800 select-none mx-auto'}>{standard.title}</p>
            <div className={'grow'} />
            <div className={'flex gap-6 w-fit mx-auto'} tabIndex={-1}>
                <OverallStandardSpeedometer
                    value={(standardData.filter(item => item.type === 'Recall').length > 0) ? standardData.filter(data => (data.result === true || data.result === 0)).length + standardData.filter(data => data.type === 'Recall').flatMap(item => item.recallItems).map(item => item.active).filter(item => item.correct === true).length / standardData.filter(data => data.type === 'Recall').flatMap(item => item.recallItems).map(item => item.active).length : standardData.filter(data => (data.result === true || data.result === 0)).length}
                    data={[
                        { name: 'Reteach', value: standardData.length * 0.6, color: '#c25022' },
                        { name: 'Check-in', value: standardData.length * 0.3, color: '#538ea5' },
                        { name: 'Move On', value: standardData.length * 0.1, color: '#f7f7f7' },
                    ]}/>
                <div className={'rounded bg-columbia_blue-700 shadow h-fit p-2 my-auto font-bold text-dutch_white-200'}>
                    <p>{(standardData.filter(item => item.type === 'Recall').length > 0) ? Math.floor((standardData.filter(item => item.result === 0 || item.result === true).length + standardData.filter(data => data.type === 'Recall').flatMap(item => item.recallItems).map(item => item.active).filter(item => item.correct === true).length / standardData.filter(data => data.type === 'Recall').flatMap(item => item.recallItems).map(item => item.active).length) / standardData.length * 100) : Math.floor(standardData.filter(item => item.result === 0 || item.result === true).length / standardData.length * 100)}%</p>
                </div>
                {/*{(standardData.filter(item => item.result === 'pending').length > 0) &&*/}
                {/*<div className={'rounded bg-dutch_white-600 shadow h-fit p-2 my-auto font-bold text-dutch_white-800'}>*/}
                {/*    <p>Pending: {standardData.filter(item => item.result === 'pending' || (item.type === 'Recall' && item.recallItems.map(item => item.active).map(item => item.correct).includes('pending'))).length}</p>*/}
                {/*</div>*/}
                {/*}*/}

            </div>
        </button>    );
}

export default StandardSummaryBlock