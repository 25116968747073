import React, {useState} from 'react';
import MTSSStudentDataSummaryModal from "./mtssStudentDataSummaryModal";

function MTSSOverview({studentData, rosters, standardElements, standardSets, assessmentItems}) {

    const [studentDataSummaryModal, setStudentDataSummaryModal] = useState(null);
    const studentDataEntries = studentData.map(item => ({
            firstName: item.firstName,
            lastName: item.lastName,
            preps: item.preps,
            correct: Math.floor(item.preps.flatMap(entry => item.active[entry]).filter(item => item.result === 0 || item.result === true).length / item.preps.flatMap(entry => item.active[entry]).length * 100),
            incorrect: Math.ceil(item.preps.flatMap(entry => item.active[entry]).filter(item => item.result === false || typeof item.result === 'number' && item.result > 0).length / item.preps.flatMap(entry => item.active[entry]).length * 100),
            pending: Math.ceil(item.preps.flatMap(entry => item.active[entry]).filter(item => item.result === 'pending').length / item.preps.flatMap(entry => item.active[entry]).length * 100),
            studentId: item.studentId
        }
    ))

    return (
        <div>
            <div className={'flex gap-2'}>
                <div className={'grow flex flex-col text-left gap-2 px-4 select-none'}>
                    <p className={'text-left content-start'}>Performance</p>
                    {studentDataEntries.filter(item => Number.isFinite(item.incorrect)).sort((a, b) => b.incorrect - a.incorrect).map(item =>
                        <div className={'flex justify-between max-w-[400px] shadow bg-paynes_gray-200 p-1 px-4 rounded hover:bg-paynes_gray-300 cursor-pointer'} onClick={() => setStudentDataSummaryModal(studentData.find(entry => entry.studentId === item.studentId))}>

                            <p className={'my-auto font-bold'}>{item.firstName} {item.lastName}</p>
                            <div className={'flex text-center'}>
                                <div className={'bg-columbia_blue-600 p-1 m-1 rounded'}>
                                    <p className={'text-columbia_blue-900 font-bold'}>{item.correct}%</p>
                                    <p className={'text-xs'}>Correct</p>
                                </div>
                                <div className={'bg-burnt_sienna-400 p-1 m-1 rounded'}>
                                    <p className={'text-columbia_blue-900 font-bold'}>{item.incorrect}%</p>
                                    <p className={'text-xs'}>Incorrect</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={'grow flex flex-col text-left gap-2 px-4 select-none'}>
                    <p>Participation</p>
                    {studentDataEntries.filter(item => Number.isFinite(item.pending)).sort((a, b) => b.pending - a.pending).map(item =>
                        <div className={'flex justify-between max-w-[400px] bg-paynes_gray-200 p-1 px-4 rounded hover:bg-paynes_gray-300 cursor-pointer shadow'} onClick={() => setStudentDataSummaryModal(studentData.find(entry => entry.studentId === item.studentId))}>

                            <p className={'my-auto font-bold'}>{item.firstName} {item.lastName}</p>
                            <div className={'flex text-center'}>
                                <div className={'bg-platinum-500 p-1 m-1 rounded'}>
                                    <p className={'text-columbia_blue-900 font-bold'}>{item.pending}%</p>
                                    <p className={'text-xs'}>Pending</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {(studentDataSummaryModal) && <MTSSStudentDataSummaryModal setStudentSummaryModal={setStudentDataSummaryModal} studentData={studentDataSummaryModal} standardSets={standardSets.filter(item => item.school !== 'brainmetertemplate')} standardElements={standardElements} /> }
        </div>
    );
}

export default MTSSOverview;