import React, {useContext, useEffect, useState} from 'react';
import userContext from "../../context/userContext";
import OverallStandardSpeedometer from "../../teacher/data/overallStandardSpeedometer";
import StandardDataModal from "../../teacher/data/standardDataModal";
import {useNavigate} from "react-router-dom";
import getWithAuth from "../../context/getWithAuth";
import Loader from "../../components/loader";
import MTSSOverview from "./mtssOverview";
import ToSModal from "../../components/tosModal";

function SchoolData() {
    const [rosters, setRosters] = useState(null);
    const [studentData, setStudentData] = useState(null);
    const [standardSets, setStandardSets] = useState(null);
    const [knowShowCharts, setKnowShowCharts] = useState(null);
    const [assessmentItems, setAssessmentItems] = useState(null);
    const [standardDataModal, setStandardDataModal] = useState(null);
    const [selectedStandardSet, setSelectedStandardSet] = useState(null);
    const user = useContext(userContext);
    const navigate = useNavigate();

    const fetchStandardSets = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getStandardSets`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setStandardSets(r)
                    })
            } catch (error) {
                console.error('Error fetching standards data:', error)
            }
        }
    }

    const fetchRosters = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getRosters`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setRosters(r)
                    })
            } catch (error) {
                console.error('Error fetching roster data:', error)
            }
        }
    }

    const fetchKnowShowCharts = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getKnowShow`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setKnowShowCharts(r)
                    })
            } catch (error) {
                console.error('Error fetching standard elements data:', error)
            }
        }
    }

    const fetchAssessmentItems = async (user) => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getAssessmentItems`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setAssessmentItems(r)
                    })
            } catch (error) {
                console.error('Error fetching assessment item data:', error)
            }
        }
    }

    const fetchStudentData = async () => {
        if (user.userContext) {
            try {
                getWithAuth(`/coordinatorplanning/getStudentData`, user.userAttributes['custom:school'])
                    .then(async r => {
                        setStudentData(r)
                    })
            } catch (error) {
                console.error('Error fetching student data:', error)
            }
        }

    }

    const handleChangeStandardSet = (e) => {
        if (e.target.value === 'mtss') {
            setSelectedStandardSet('mtss')
        } else {
        setSelectedStandardSet(standardSets.find(item => item.title === e.target.value))
        }
    }

    useEffect(() => {
        fetchRosters();
        fetchStandardSets();
        fetchKnowShowCharts(user);
        fetchAssessmentItems(user);
        fetchStudentData();
    }, [user.userContext]);

    if (!user.userContext || !user.userContext) {
        return <Loader message={'Fetching user data'}/>
    } else if (!user.userContext.termsAccepted) {
        return <ToSModal user={user}/>
    } else if (user.userContext.role === 'student') {
        navigate('/home')
    } else if (user.userContext.role === 'teacher') {
        navigate('/planning')
    } else if (!studentData || !assessmentItems || !rosters || !knowShowCharts) {
        return <Loader message={`Fetching: ${(!rosters) ? "Rosters, " : ""}${(!studentData) ? "Student Data, " : ""}${(!knowShowCharts) ? "Standard Elements, " : ""}${(!assessmentItems) ? "Assessment Items" : ""}`}/>
    } else {
        return (
            <div className={'min-h-screen'}>
                <div className={'mb-2 flex'}>
                    <select className={"rounded p-2 ml-4 bg-columbia_blue-800 text-dutch_white-300 font-bold"}
                            onChange={handleChangeStandardSet} defaultValue={""}>
                        <option disabled value={""}>Select a Standard Set</option>
                        {(standardSets) && standardSets.filter(item => item.school !== 'brainmetertemplate').sort((a, b) => a.prepTitle.toLowerCase().localeCompare(b.prepTitle.toLowerCase()))
                            .map(standardSet => <option key={standardSet.title}
                                                        value={standardSet.title}>{standardSet.prepTitle}</option>)}
                        <option key={'mtss'} value={'mtss'}>MTSS Overview</option>
                    </select>

                    {(!selectedStandardSet) &&
                        <div className={"h-10 flex text-center"} style={{ animation: 'bounce-horizontally 3s ease-in-out infinite' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="20" height="30" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left my-auto">
                                <line x1="19" y1="12" x2="5" y2="12"></line>
                                <polyline points="12 19 5 12 12 5"></polyline>
                            </svg>
                            <p className={"my-auto"}>Start here! Choose a standard set</p>
                        </div>}
                </div>



                {(selectedStandardSet) && (selectedStandardSet !== 'mtss') ?
                        <div className={'bg-columbia_blue-700 p-2'}>
                            <p className={'text-4xl font-bold text-dutch_white-400 mb-4'}>{selectedStandardSet.prepTitle}</p>
                            {(!rosters) ? <div
                                    className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-burnt_sienna mx-auto"/>
                                : (!studentData)
                                    ? <div
                                        className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-burnt_sienna mx-auto"/>
                                    : <div className={'grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'}>
                                        {selectedStandardSet.standards.map((standard) => (
                                            (rosters && studentData) ?
                                                (studentData.filter(student => student.preps.includes(selectedStandardSet.prepTitle)).flatMap(item => item.active[selectedStandardSet.prepTitle]).filter(item => item.standard === standard.standardCode).length > 0)
                                                    ?
                                                    <div
                                                        className={'flex flex-col rounded w-full bg-dutch_white-400 border-[3px] border-dutch_white-800 shadow-lg text-columbia_blue-800 py-2 px-4 hover:bg-dutch_white-500 cursor-pointer overflow-x-hidden'}
                                                        onClick={() => setStandardDataModal(
                                                            {
                                                                standard: standard,
                                                                standardCode: standard.standardCode,
                                                                standardSet: selectedStandardSet
                                                            }
                                                        )}>
                                                        <p className={'font-bold text-columbia_blue-700 select-none'}>{standard.code}</p>
                                                        <p className={'text-columbia_blue-700 select-none'}>{standard.title}</p>
                                                        <div className={'grow'}/>
                                                        <div className={'flex'}>
                                                            <OverallStandardSpeedometer
                                                                value={studentData.filter(student => student.preps.includes(selectedStandardSet.prepTitle)).flatMap(item => item.active[selectedStandardSet.prepTitle]).filter(data => (data.standard === standard.standardCode && data.result !== 'pending' && (data.result === true || data.result === 0))).length}
                                                                data={[
                                                                    {
                                                                        name: 'Reteach',
                                                                        value: Math.ceil(studentData.filter(student => student.preps.includes(selectedStandardSet.prepTitle)).flatMap(item => item.active[selectedStandardSet.prepTitle]).filter(data => (data.standard === standard.standardCode)).length * 0.6),
                                                                        color: '#c25022'
                                                                    },
                                                                    {
                                                                        name: 'Check-in',
                                                                        value: Math.ceil(studentData.filter(student => student.preps.includes(selectedStandardSet.prepTitle)).flatMap(item => item.active[selectedStandardSet.prepTitle]).filter(entry => (entry.standard === standard.standardCode)).length * 0.3),
                                                                        color: '#538ea5'
                                                                    },
                                                                    {
                                                                        name: 'Move On',
                                                                        value: Math.ceil(studentData.filter(student => student.preps.includes(selectedStandardSet.prepTitle)).flatMap(item => item.active[selectedStandardSet.prepTitle]).filter(entry => (entry.standard === standard.standardCode)).length * 0.1),
                                                                        color: '#f7f7f7'
                                                                    },
                                                                ]}/>
                                                            <div
                                                                className={'hidden lg:block rounded bg-dutch_white-600 shadow h-fit p-2 my-auto font-bold text-dutch_white-800 w=[30%]'}>
                                                                <p className={'truncate'}>Pending: {studentData.filter(student => rosters.filter(roster => roster.prep === selectedStandardSet.prepTitle).flatMap(roster => roster.students).includes(student.studentId)).flatMap(item => item.active[selectedStandardSet.prepTitle]).filter(item => item.standard === standard.standardCode && item.result === 'pending').length}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div
                                                        className={'rounded w-full bg-columbia_blue-400 text-columbia_blue-800 py-2 px-4 flex flex-col'}>
                                                        <p className={'font-bold text-columbia_blue-700 select-none'}>{standard.code}</p>
                                                        <p className={'text-columbia_blue-700 select-none'}>{standard.title}</p>
                                                        <div className={'grow'} />
                                                        <p className={'text-4xl my-4 text-platinum-700 font-bold select-none'}>No
                                                            Data Collected</p>
                                                    </div>
                                                : <></>))}
                                    </div>}
                        </div>
                : <MTSSOverview studentData={studentData} standardSets={standardSets} rosters={rosters} standardElements={knowShowCharts} assessmentItems={assessmentItems}/>}
                {(standardDataModal) &&
                    <StandardDataModal rosters={rosters} studentData={studentData} knowShowCharts={knowShowCharts}
                                       assessmentItems={assessmentItems} standardDataModal={standardDataModal}
                                       setStandardDataModal={setStandardDataModal}/>}

            </div>
        );
    }
}

export default SchoolData;