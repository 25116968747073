import React from 'react';

function ImageMultipleChoiceEntry({ questionModalContent, selectedAnswer, setSelectedAnswer }) {

    function stripHtml(html) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;

        // Handle superscript and subscript with semantic meaning
        tempDiv.querySelectorAll('sup').forEach(sup => {
            const content = sup.textContent.trim();
            let replacement = `superscript ${content}`;
            if (content === '2') {
                replacement = 'squared';
            } else if (content === '3') {
                replacement = 'cubed';
            }
            sup.replaceWith(replacement);
        });

        return tempDiv.textContent || tempDiv.innerText || '';
    }

    return (
        <div className={'bg-columbia_blue p-2'}>
            {(questionModalContent.imageURL) && <img src={questionModalContent.imageURL} className={"rounded w-80 mx-auto mb-2 p-2 bg-dutch_white-300 shadow border-dutch_white-600 border-2"} />}
            <div tabIndex={0} aria-label={`Assessment Item Prompt: ${stripHtml(questionModalContent.prompt)}`} className={"bg-dutch_white-300 text-lg p-4 rounded text-dutch_white-800 border-dutch_white-600 border-2 shadow"}>{questionModalContent.prompt}</div>
            <div className={'grid grid-cols-2 gap-2 mb-4 mt-2'}>
                {questionModalContent.answers.map(item => (selectedAnswer === item)
                    ? <button
                        className={"bg-columbia_blue-800 text-dutch_white-100 p-2 border-dutch_white-100 border-2 rounded flex flex-col"} aria-label={'Selected Answer'}>
                        <div className={'grow'}/>
                        <img className={'max-w-[150px] rounded mx-auto'} src={item}/>
                        <div className={'grow'}/>
                    </button>
                    : <button onClick={() => setSelectedAnswer(item)} aria-label={'Possible Answer'} className={"bg-columbia_blue-700 text-dutch_white-100 p-2 border-gray-500 border-2 rounded flex flex-col"}>
                        <div className={'grow'} />
                        <img className={'max-w-[150px] rounded mx-auto my-auto'} src={item} />
                        <div className={'grow'} />
                    </button>
                )}
            </div>

        </div>
    );
}

export default ImageMultipleChoiceEntry;