import React from 'react';
import { signOut } from "aws-amplify/auth";

function StudentNavBar() {
    return (
        <div className={'flex justify-between bg-columbia_blue-800 border-dutch_white-600 border-b-4'}>
            <div className={'grow'}></div>
            <div className={"px-4"}>
                <img className={'h-[60px] w-[60px] my-auto'}
                     src={'https://ucarecdn.com/015c824d-7b4e-41e7-885c-a838e592582b/NewLogo.png'}
                     alt={'Brainmeter Logo'}
                     role="img" />
            </div>
            <div className={'grow'}></div>
            <button
                className={'flex items-center max-w-min py-1 text-dutch_white-300 font-bold px-8 bg-columbia_blue-800 cursor-pointer focus:bg-columbia_blue-700 hover:bg-columbia_blue-700 border-x border-dutch_white-800'}
                onClick={() => signOut()}
                aria-label="Sign out from your account">
                Sign Out
            </button>
        </div>
    );
}

export default StudentNavBar;