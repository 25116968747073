import React from 'react';
import DOMPurify from 'dompurify';
import ImageMCScrambledPreview from "../data/imageMCScrambledPreview";
import MatchingScrambledPreview from "../data/matchingScrambledPreview";
import SortingScrambledPreview from "../data/sortingScrambledPreview";
import TraditionalMCScrambledPreview from "../data/traditionalMCScrambledPreview";


function ViewAssignmentItem({assessmentItem}) {

    return (
        <>
            {(Object.keys(assessmentItem).includes('type')) && (assessmentItem.type === 'Image MC') ? <>
                    <div className={'flex flex-col gap-2 bg-columbia_blue-400 p-2 rounded max-w-[400px]'}>
                        <ImageMCScrambledPreview assessmentItem={assessmentItem} />
                        {/*<div className={'bg-platinum rounded p-2 border-2 border-columbia_blue'}>*/}
                        {/*    <p>{content.prompt}</p>*/}
                        {/*</div>*/}
                        {/*<div className={'grid grid-cols-2'}>*/}
                        {/*    <div className={'bg-columbia_blue-600 rounded p-2 w-fit mx-auto h-fit my-auto'}>*/}
                        {/*        <img className={'max-w-[150px]'} src={assessmentItem.correct} />*/}
                        {/*    </div>*/}
                        {/*    <div className={'bg-burnt_sienna rounded p-2 w-fit mx-auto h-fit my-auto'}>*/}
                        {/*        <img className={'max-w-[150px]'} src={assessmentItem.distractor1} />*/}
                        {/*    </div>*/}
                        {/*    <div className={'bg-burnt_sienna rounded p-2 w-fit mx-auto h-fit my-auto'}>*/}
                        {/*        <img className={'max-w-[150px]'} src={assessmentItem.distractor2} />*/}
                        {/*    </div>*/}
                        {/*    <div className={'bg-burnt_sienna rounded p-2 w-fit mx-auto h-fit my-auto'}>*/}
                        {/*        <img className={'max-w-[150px]'} src={assessmentItem.distractor3} />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </>
                : (Object.keys(assessmentItem).includes('type')) && (assessmentItem.type === 'Sorting') ?
                    <>
                        <div className={'flex p-1 bg-dutch_white-400 max-w-[400px]'}>
                            <SortingScrambledPreview assessmentItem={assessmentItem} />
                            {/*{(Object.keys(assessmentItem).includes('imageURL')) && <img className={`max-w-[200px] max-h-[200px] aspect-square rounded my-auto`} src={assessmentItem.imageURL} />}*/}
                            {/*<div className={'flex flex-col gap-2 bg-dutch_white-400 p-2 rounded'}>*/}
                            {/*    <div className={'bg-platinum rounded p-2 border-2 border-columbia_blue'}>*/}
                            {/*        <div dangerouslySetInnerHTML={renderHtml(content.prompt)} />*/}
                            {/*    </div>*/}
                            {/*    <div className={'flex flex-col gap-4 max-h-[800px] overflow-auto'}>*/}
                            {/*    {assessmentItem.matchSets.map(item => <div className={'flex'}>*/}
                            {/*            <div className={'p-2 rounded bg-columbia_blue-400 shadow border-dutch_white-200 border-2 my-auto h-fit'}>*/}
                            {/*                {(item.prompt.type === 'text') ? <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} className={'my-auto'}/> : <img className={'max-h-[100px]'} src={item.prompt.content}/>}*/}
                            {/*            </div>*/}
                            {/*            <div className={'border-dotted border-b-4 border-columbia_blue-700 grow h-1/2'}></div>*/}
                            {/*            <div className={'p-2 rounded bg-burnt_sienna-300 shadow border-dutch_white-200 border-2 my-auto h-fit'}>*/}
                            {/*                {(item.match.type === 'text') ? <div dangerouslySetInnerHTML={renderHtml(item.match.content)} className={'my-auto'}/> : <img className={'max-h-[100px]'} src={item.match.content}/>}*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        )}*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </>

                    : (Object.keys(assessmentItem).includes('type')) && (assessmentItem.type === 'Matching') ?
                        <>
                            <div className={'flex p-1 bg-dutch_white-400 max-w-[400px]'}>
                                <MatchingScrambledPreview assessmentItem={assessmentItem} />
                                {/*{(Object.keys(assessmentItem).includes('imageURL')) && <img className={`max-w-[200px] max-h-[200px] aspect-square rounded my-auto`} src={assessmentItem.imageURL} />}*/}
                                {/*<div className={'flex flex-col gap-2 bg-dutch_white-400 p-2 rounded'}>*/}
                                {/*    <div className={'bg-platinum rounded p-2 border-2 border-columbia_blue'}>*/}
                                {/*        <div dangerouslySetInnerHTML={renderHtml(content.prompt)} />*/}
                                {/*    </div>*/}
                                {/*    <div className={'flex flex-col gap-4 max-h-[800px] overflow-auto'}>*/}
                                {/*    {assessmentItem.matchSets.map(item => <div className={'flex'}>*/}
                                {/*            <div className={'p-2 rounded bg-columbia_blue-400 shadow border-dutch_white-200 border-2 my-auto h-fit'}>*/}
                                {/*                {(item.prompt.type === 'text') ? <div dangerouslySetInnerHTML={renderHtml(item.prompt.content)} className={'my-auto'}/> : <img className={'max-h-[100px]'} src={item.prompt.content}/>}*/}
                                {/*            </div>*/}
                                {/*            <div className={'border-dotted border-b-4 border-columbia_blue-700 grow h-1/2'}></div>*/}
                                {/*            <div className={'p-2 rounded bg-burnt_sienna-300 shadow border-dutch_white-200 border-2 my-auto h-fit'}>*/}
                                {/*                {(item.match.type === 'text') ? <div dangerouslySetInnerHTML={renderHtml(item.match.content)} className={'my-auto'}/> : <img className={'max-h-[100px]'} src={item.match.content}/>}*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*        )}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </>
                        : <>
                            <div className={'flex p-1 bg-dutch_white-400 max-w-[400px]'}>
                                <TraditionalMCScrambledPreview assessmentItem={assessmentItem} />
                                {/*{(Object.keys(assessmentItem).includes('imageURL')) && <img className={`max-w-[200px] max-h-[200px] aspect-square rounded my-auto`} src={assessmentItem.imageURL} />}*/}
                                {/*<div className={'flex flex-col gap-2 bg-dutch_white-400 p-2 rounded'}>*/}
                                {/*    <div className={'bg-platinum rounded p-2 border-2 border-columbia_blue'}>*/}
                                {/*        <div dangerouslySetInnerHTML={renderHtml(content.prompt)} />*/}
                                {/*    </div>*/}
                                {/*    <div className={'bg-columbia_blue rounded p-2'}>*/}
                                {/*        <div dangerouslySetInnerHTML={renderHtml(content.correct)} />*/}
                                {/*    </div>*/}
                                {/*    <div className={'bg-burnt_sienna-300 rounded p-2'}>*/}
                                {/*        <div dangerouslySetInnerHTML={renderHtml(content.distractor1)} />*/}
                                {/*    </div>*/}
                                {/*    <div className={'bg-burnt_sienna-300 rounded p-2'}>*/}
                                {/*        <div dangerouslySetInnerHTML={renderHtml(content.distractor2)} />*/}
                                {/*    </div>*/}
                                {/*    <div className={'bg-burnt_sienna-300 rounded p-2'}>*/}
                                {/*        <div dangerouslySetInnerHTML={renderHtml(content.distractor3)} />*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </>
            }
        </>
    );
}

export default ViewAssignmentItem;